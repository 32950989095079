<template>
  <div class="container">
    <!-- 用户   -->
    <div class="container_user">
      <div class="container_user_name">
        <div class="container_user_name_left">
          姓名
        </div>
        <div class="container_user_name_right">
          {{ user?.username }}
        </div>
      </div>
      <div class="container_user_name">
        <div class="container_user_name_left">
          性别
        </div>
        <div class="container_user_name_right">
          {{ user?.sex==="0"?'男':"女" }}
        </div>
      </div>
      <div class="container_user_name">
        <div class="container_user_name_left">
          所在站点
        </div>
        <div class="container_user_name_right">
          {{ user?.vlStation?.name }}
        </div>
      </div>
      <div class="container_user_name">
        <div class="container_user_name_left">
          服务队
        </div>
        <div class="container_user_name_right">
          {{ user?.vlTeam?.name }}
        </div>
      </div>

    </div>
    <!--  单位  -->
    <div class="container_company">
      <div class="container_company_left">
        工作单位
      </div>
      <div class="container_company_right">
        <van-field
            v-model="company"
            input-align="right"
            placeholder="请输入"
        />
      </div>
    </div>
<!--    <div class="container_company" v-if="data.longType=='1'">
      <div class="container_company_left">
        活动时间
      </div>
      <div class="container_company_right">
        <van-field
            v-model="childName"
            placeholder="请选择"
            readonly
            @click="childShow = true"
            input-align="right"
        />
      </div>
    </div>-->
  </div>
  <div class="container_bottom">
    <van-button @click="confirmBtn()">立即报名</van-button>
  </div>
  <!-- 按钮   -->
  <van-popup
      v-model:show="show"
      position="bottom"
      :style="{ height: '35%' }"
  >
    <van-datetime-picker
        v-model="currentDate"
        type="date"
        @cancel="show = false"
        @confirm="confirmPicker"
    />
  </van-popup>
  <van-popup
      v-model:show="childShow"
      position="bottom"
      :style="{ height: '35%' }"
  >
    <van-picker
        title="活动时间"
        :columns="childColumns"
        @confirm="onConfirmChild"
        @cancel="childShow=false"
    />
  </van-popup>
</template>

<script setup>
import {useRoute, useRouter} from "vue-router";

const route = useRoute();
const router = useRouter();
const id = route.query.id;


import {ref} from "vue";
import {getUserInfo} from "@/api/user";
import {Dialog, Toast} from "vant";
import {getActivityListNotPage, signup} from "@/api/activity";
import {getDetail} from "@/api/activity";
const user=ref({});
const company = ref('');
const data=ref({});
const childShow=ref(false);
const childId=ref('');
const childName=ref('');

const onConfirmChild = (val) => {
  childName.value = val.text
  childId.value = val.id
  childShow.value = false
}

const childColumns=ref([])
const openChild = async () => {
  //查询子活动列表
  let response = await getActivityListNotPage({mainId: id, isMain: "1", checkStatus: "1"});
  childColumns.value = response.data
  if(childColumns.value.length>0){
    for (let e of childColumns.value) {
      e.text = e.starttime+"至"+e.endtime
    }
  }
}
openChild();

const getUser=async ()=>{
  let response = await getUserInfo();
  user.value=response.data;
}
const getAcDetail= async ()=>{
  let response=await getDetail(id);
  data.value=response.data
}
getAcDetail();
getUser()

const confirmBtn= ()=>{

  Dialog.confirm({
    title: '我要报名',
    message:
        '我要报名当前活动',
  })
      .then(async() => {
        //判断支付方式
        let q={
            company:company.value
        }
          q.aid=id
        //1.不扣钱，不和积分挂钩
        if(data.value.money === 0 && data.value.score === 0){
          let response =await signup(q);
          console.log(response)
          Toast({
            message: '报名成功',
            icon: 'success',
            duration: 2000
          })
          router.push({
            path: "/activity"
          });
        }else if(data.value.money === 0 && data.value.score != 0){
          //2.不扣钱，和积分挂钩
          let response =await signup(q);
          console.log(response)
          Toast({
            message: '报名成功',
            icon: 'success',
            duration: 2000
          })
          router.push({
            path: "/activity"
          });
        }else if(data.value.money != 0 && data.value.score === 0){
          //3.扣钱,不和积分挂钩
          if(data.value.longType=='1'){
            router.push({
              path: "/activityPay",
              query: {
                id:childId.value
              },
            });
          }else {
            router.push({
              path: "/activityPay",
              query: {
                id
              },
            });
          }

        }else {
          if(data.value.longType=='1'){
            router.push({
              path: "/activityPay",
              query: {
                id:childId.value
              },
            });
          }else {
            router.push({
              path: "/activityPay",
              query: {
                id
              },
            });
          }
        }

      })
      .catch(() => {
        // on cancel
      });


}


//const checked = ref(['1', '2']);
const startTime = ref('');

const show = ref(false);
const currentDate = ref(new Date())
const confirmPicker = (val) => {
  const year = val.getFullYear() // 年
  let month = val.getMonth() + 1 // 月
  if (month >= 1 && month <= 9) {
    month = `0${month}`
  }
  let date = val.getDate()  // 日
  if (date >= 1 && date <= 9) {
    date = `0${date}`
  }
  startTime.value = `${year}-${month}-${date}`
  show.value = false
}
</script>

<style lang="scss" scoped>

.container {
  position: relative;
  height: calc(100vh - 130px) !important;
  overflow-y: scroll;
  padding: 10px 10px 10px 10px;

  &_user {
    line-height: 20px;
    border-radius: 6px;
    background-color: rgba(255, 255, 255, 100);
    color: rgba(16, 16, 16, 100);
    font-size: 14px;
    text-align: center;
    font-family: Arial;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px 0 40px 30px;

    &_name {
      display: flex;
      width: 100%;
      margin-top: 16px;

      &_left {
        flex: 3;
        text-align: left;
        height: 16px;
        color: rgba(158, 158, 158, 100);
        font-size: 14px;
        font-family: PingFangSC-regular;
      }

      &_right {
        flex: 6;
        text-align: left;
        color: #212121;
        font-size: 14px;
        height: 16px;
        font-family: PingFangSC-medium;
        font-weight: bolder;
      }
    }
  }

  &_company {
    margin-top: 10px;
    line-height: 20px;
    border-radius: 6px;
    background-color: rgba(255, 255, 255, 100);
    color: rgba(16, 16, 16, 100);
    font-size: 14px;
    text-align: center;
    font-family: Arial;
    display: flex;
    align-items: center;
    padding: 10px;

    &_left {
      color: rgba(33, 33, 33, 100);
      font-size: 16px;
      text-align: left;
      font-family: PingFangSC-regular;
      flex: 1;
    }

    &_right {
      margin-right: 29px;
      text-align: right;
      .van-tag {
        margin-left: 12px;
        height: 30px;
        width: 25px;
        border-radius: 4px;
        font-size: 14px;
        font-family: Microsoft Yahei;
        padding-left: 15px;
      }
      input {
        border: none;
        background: none;
        color: rgba(33, 33, 33, 100);
        font-size: 16px;
        text-align: left;
        font-family: PingFangSC-medium;
      }

      input::placeholder {
        color: rgba(189, 189, 189, 100);
        font-size: 14px;
        text-align: left;
        font-family: PingFangSC-regular;
      }
    }

  }

  &_activity {
    margin-top: 10px;
    line-height: 20px;
    border-radius: 6px;
    background-color: rgba(255, 255, 255, 100);
    color: rgba(16, 16, 16, 100);
    font-size: 14px;
    text-align: center;
    font-family: Arial;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 18px 10px 18px 10px;

    &_des {
      width: 335px;
      color: rgba(33, 33, 33, 100);
      font-size: 16px;
      text-align: left;
      font-family: PingFangSC-regular;
    }

    &_input {
      margin-top: 18px;
      color: rgba(189, 189, 189, 100);
      font-family: PingFangSC-regular;

      textarea {
        width: 100%;
        border: none;
        background: none;
        color: rgba(33, 33, 33, 100);
        font-size: 16px;
        text-align: left;
        font-family: PingFangSC-medium;
        resize: none;
      }

      textarea::placeholder {
        color: rgba(189, 189, 189, 100);
        font-size: 14px;
        text-align: left;
        font-family: PingFangSC-regular;
      }
    }


  }

  &_week {
    margin-top: 10px;
    line-height: 20px;
    border-radius: 6px;
    background-color: rgba(255, 255, 255, 100);
    color: rgba(16, 16, 16, 100);
    font-size: 14px;
    text-align: center;
    font-family: Arial;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 18px 10px 18px 10px;

    &_top {
      display: flex;
      text-align: left;
      line-height: 18px;
      width: 335px;

      &_left {
        color: rgba(33, 33, 33, 100);
        font-size: 16px;
        text-align: left;
        font-family: PingFangSC-regular;
      }

      &_right {
        color: rgba(158, 158, 158, 100);
        font-size: 16px;
        text-align: left;
        font-family: PingFangSC-regular;
      }
    }

    &_line {
      margin-top: 18px;
      border: 1px solid #F5F5F5;
      width: 335px;
    }

    .van-checkbox-group {
      .van-checkbox {
        margin-top: 18px;
      }
    }
  }

  &_time {
    margin-top: 10px;
    display: flex;
    align-items: center;
    line-height: 20px;
    border-radius: 6px;
    background-color: rgba(255, 255, 255, 100);
    color: rgba(16, 16, 16, 100);
    font-size: 14px;
    font-family: Arial;

    &_left {
      margin-left: 10px;
      line-height: 18px;
      color: rgba(33, 33, 33, 100);
      font-size: 16px;
      text-align: left;
      font-family: PingFangSC-regular;
      flex: 1;
    }

    &_right {
      margin-top: 5px;
      margin-bottom: 5px;

      .van-field {
        width: 130px;
        line-height: 18px;
        color: rgba(33, 33, 33, 100);
        font-size: 16px;
        font-family: PingFangSC-medium;
      }
    }
  }

}

.container_bottom {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 64px;
  line-height: 20px;
  background-color: rgba(255, 255, 255, 100);
  color: rgba(16, 16, 16, 100);
  font-size: 14px;
  text-align: center;
  box-shadow: 0px -1px 1px 0px rgba(238, 238, 238, 100);
  font-family: Arial;

  .van-button {
    margin: 10px;
    width: 355px;
    height: 44px;
    border-radius: 6px;
    background-color: #FFBF1E;
    text-align: center;
    color: rgba(255, 255, 255, 100);
    font-size: 14px;
    font-family: PingFangSC-semiBold;
    font-weight: bolder;
  }
}
</style>
