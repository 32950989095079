<template>
  <div class="container">
    <div class="container_content">

      <div class="container_main_address" @click="toEditAddress">
        <div class="container_main_address_title">
          <img
            src="../../assets/images/shop/location.png"
            alt=""
          >
          <span>收货地址</span>
        </div>
        <div class="container_main_address_info">
          <van-cell
            :title="address"
            is-link
          />
        </div>
      </div>

      <template
        v-for="item in list"
        :key="item"
      >
        <div class="container_item">
          <div class="container_item_checkbox">
            <van-checkbox
              :name="item?.id"
              icon-size="16px"
              v-model="item.check"
            ></van-checkbox>
          </div>
          <div class="container_item_main">
            <div class="container_item_main_pic">
              <van-image
                :src="item?.vlShopGoods?.images[0].url"
                alt=""
                height="60px"
                width="60px"
              />
            </div>
            <div class="container_item_main_info">
              <div class="container_item_main_info_title">{{item?.vlShopGoods?.name}}</div>

              <template v-if="item?.vlShopGoods?.payType==='1'">
                <div class="container_item_main_info_price">&yen;{{item?.vlShopGoods?.isRebate==='1'? item.vlShopGoods?.originalPrice : item.vlShopGoods?.discountPrice}} <span class="container_item_main_info_price_tag">/{{item.vlShopGoods?.vlUnit?.unit}}</span></div>
                <div class="container_item_main_score">
                  <div
                    class="container_item_main_score_desc"
                    v-show="false"
                  >积分：- {{item.vlShopGoods.score}}</div>
                  <div class="container_item_main_score_handle">
                    <van-stepper
                      v-model="item.num"
                      button-size="22"
                      disable-input
                      disabled
                    />
                  </div>
                </div>
              </template>
              <template v-else-if="item?.vlShopGoods?.payType==='2'">
                <div
                  class="container_item_main_info_price"
                  v-if="1>2"
                >&yen;{{item.vlShopGoods?.isRebate==='1'? item.vlShopGoods?.originalPrice : item.vlShopGoods?.discountPrice}} <span class="container_item_main_info_price_tag">/{{item.vlShopGoods?.vlUnit?.unit}}</span></div>
                <div class="container_item_main_score">
                  <div class="container_item_main_score_desc">积分：- {{item.vlShopGoods.score}}</div>
                  <div class="container_item_main_score_handle">
                    <van-stepper
                      v-model="item.num"
                      button-size="22"
                      disable-input
                      disabled
                    />
                  </div>
                </div>
              </template>
              <template v-else-if="item?.vlShopGoods?.payType==='3'">
                <div class="container_item_main_info_price">&yen;{{item.vlShopGoods?.isRebate==='1'? item.vlShopGoods?.originalPrice : item.vlShopGoods?.discountPrice}} <span class="container_item_main_info_price_tag">/{{item.vlShopGoods?.vlUnit?.unit}}</span></div>
                <div class="container_item_main_score">
                  <div class="container_item_main_score_desc">积分：- {{item.vlShopGoods.score}}</div>
                  <div class="container_item_main_score_handle">
                    <van-stepper
                      v-model="item.num"
                      button-size="22"
                      disable-input
                      disabled
                    />
                  </div>
                </div>
              </template>

              <!-- <div class="container_item_main_info_price">&yen;{{item?.vlShopGoods?.isRebate==='1'? item?.vlShopGoods?.originalPrice : item?.vlShopGoods?.discountPrice}} <span class="container_item_main_info_price_tag">/{{item?.vlShopGoods?.vlUnit?.unit}}</span></div>
                        <div class="container_item_main_score">
                            <div class="container_item_main_score_desc">积分：- 15</div>
                            <div class="container_item_main_score_handle"><van-stepper v-model="item.num"  button-size="22" disable-input disabled />
                        </div>
                        </div> -->

            </div>
          </div>
        </div>
      </template>

      <div class="container_pay">
        <van-radio-group v-model="checked">
          <van-cell-group inset>
            <van-cell
              title="微信支付"
              clickable
              @click="chooseItem('1')"
            >
              <template #icon>
                <img
                  src="../../assets/images/shop/wxpay.png"
                  alt=""
                  class="custom-icon"
                />
              </template>
              <template #right-icon>
                <van-radio
                  name="1"
                  icon-size="16px"
                />
              </template>
            </van-cell>
            <van-cell
              title="余额支付"
              clickable
              @click="chooseItem('2')"
            >
              <template #icon>
                <img
                  src="../../assets/images/shop/leftpay.png"
                  alt=""
                  class="custom-icon"
                />
              </template>
              <template #right-icon>
                <van-radio
                  name="2"
                  icon-size="16px"
                />
              </template>
            </van-cell>
            <van-cell
              title="支付宝支付"
              clickable
              @click="chooseItem('3')"
            >
              <template #icon>
                <img
                  src="../../assets/images/shop/all.png"
                  alt=""
                  class="custom-icon"
                />
              </template>
              <template #right-icon>
                <van-radio
                  name="3"
                  icon-size="16px"
                />
              </template>
            </van-cell>
            <van-cell
              title="积分支付"
              clickable
              @click="chooseItem('4')"
            >
              <template #icon>
                <img
                  src="../../assets/images/shop/scorepay.png"
                  alt=""
                  class="custom-icon"
                />
              </template>
              <template #right-icon>
                <van-radio
                  name="4"
                  icon-size="16px"
                />
              </template>
            </van-cell>
          </van-cell-group>
        </van-radio-group>
      </div>
    </div>
    <div class="container_bottom">
      <van-button
        type="warning"
        size="large"
        color="#FFD11A"
        @click="confirmPay"
      >确认支付 &yen;{{total}} +积分{{totalScore}}</van-button>
    </div>
  </div>
</template>
<script setup name="ShopPay">
import { ref } from '@vue/reactivity';
import { useRoute, useRouter } from 'vue-router';
import wx from 'weixin-js-sdk'
import { getSignature, wxPay } from '../../api/wxreq';
import { getDefaultAddress } from '../../api/shop';
import { Toast } from 'vant';
import { Dialog } from 'vant';
import { getOpenId } from '../../utils/auth';
import {authorize, getUserInfo} from '@/api/user';
import url from '@/utils/URL'

const router = useRouter();
const checked = ref('1')
const address = ref('')
const addressId = ref();
const list = ref([])
const total = ref(0)
const totalScore = ref(0)
const route = useRoute();
const user = ref({});
console.log("listss==", JSON.parse(route.params.list))
console.log("total===", route.params.totalPrice)

const toEditAddress=async ()=>{
  router.push({
    path: "editAddress",
    query: {
      id:addressId.value,
      edit: 1,
    },
  })
}
const getDefaultAddr = async () => {
  let result = await getDefaultAddress()
  list.value = JSON.parse(route.params.list)
  total.value = route.params.totalPrice
  totalScore.value = route.params.totalScore
  console.log(result)
  if (result.code === 200) {
    address.value = result.data[0].addressName + result.data[0].address
    addressId.value = result.data[0].id;
  }else if (result.code === 410){
    Dialog.confirm({
      title: '收货地址',
      message:
          '当前不存在默认收货地址，是否跳转到收货地址页面，进行设置？',
    })
        .then(() => {
          router.push({ name: "ReceiveAddress" })
        })
        .catch(() => {
          // on cancel
        });
  }

}

getDefaultAddr();

const confirmPay = async () => {
  if(total.value != 0 && totalScore.value  == 0 && checked.value == '4'){
    Toast("此商品不支持使用积分支付,请选择其他支付方式");
    return;
  }
  //如果商品是有积分，没有钱，那么，钱相关的应该也不能选
  if(total.value == 0 && totalScore.value  != 0 && checked.value == '1'){
    Toast("此商品只支持积分支付,请选择积分支付");
    return;
  }
  if(total.value == 0 && totalScore.value  != 0 && checked.value == '2'){
    Toast("此商品只支持积分支付,请选择积分支付");
    return;
  }
  if(total.value == 0 && totalScore.value  != 0 && checked.value == '3'){
    Toast("此商品只支持积分支付,请选择积分支付");
    return;
  }
  if(user.value.money == null || user.value.money/100 < total.value){
    if(checked.value == '2'){
      Toast("您的余额不足，不能使用余额支付");
      return;
    }
  }
  if(addressId.value == null){
    Toast("收货地址不能为空");
    return;
  }
  //const url = url
  let result = await getSignature({ url: url.fontUrl })
  if (result.code === 200) {
    const sign = result.data
    wx.config({
      debug: false,
      appId: sign.appid,
      timestamp: sign.timestamp,
      nonceStr: sign.noncestr,
      signature: sign.signature,
      jsApiList: [
        'chooseWXPay'
      ]
    })
    let openid = getOpenId();
    console.error(total.value);
    console.error(totalScore.value);
    if (openid == null && (checked.value == '1' || checked.value == '3')) {
      console.error("进来了");
      let result = await authorize("shopPay");
      window.location.href = result;
      return;
    }
    let payResult = await wxPay({ cartList: list.value, totalFee: total.value, totalScore: totalScore.value, payType: checked.value, openid: openid, payway: 'js',addressId:addressId.value });
    if (payResult?.code === 200) {
      console.log("payresult==", payResult);
      //微信支付
      if (checked.value == '1') {
        wx.ready(() => {
          wx.chooseWXPay({
            timestamp: payResult?.data.timeStamp, // 支付签名时间戳，注意微信 jssdk 中的所有使用 timestamp 字段均为小写。但最新版的支付后台生成签名使用的 timeStamp 字段名需大写其中的 S 字符
            nonceStr: payResult?.data.nonceStr, // 支付签名随机串，不长于 32 位
            package: payResult?.data.packageValue, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
            signType: payResult?.data.signType, // 微信支付V3的传入 RSA ,微信支付V2的传入格式与V2统一下单的签名格式保持一致
            paySign: payResult?.data.paySign, // 支付签名
            success: function (res) {
              // 支付成功后的回调函数
              console.log(res)
              router.push({ name: "ShopPayStatus" })
            }
          });
        })
      }
      //支付宝支付
      if (checked.value == '3') {
        console.log("支付宝支付")
        const div = document.createElement('div')
        div.innerHTML = payResult.data //此处form就是后台返回接收到的数据
        document.body.appendChild(div)
        document.forms[0].submit()
        console.log("支付宝")
      }
      Toast(payResult.msg)
      router.push({ name: "Shop" })
      //router.go(-1)
    }

  }


}


const getUser = async () => {
  console.error("执行了");
  let response = await getUserInfo();
  user.value = response.data
}
getUser();

const chooseItem = (index) => {
  if(index === '3'){
    Toast("暂不支持使用支付宝支付,请选择其他支付方式");
    return;
  }
  //判断商品是否可以积分支付
  if(total.value != 0 && totalScore.value  == 0 && index == '4'){
    checked.value = '1';
    Toast("此商品不支持使用积分支付,请选择其他支付方式");
    return;
  }
  //如果商品是有积分，没有钱，那么，钱相关的应该也不能选
  if(total.value == 0 && totalScore.value  != 0 && index == '1'){
    checked.value = '4';
    Toast("此商品只支持积分支付,请选择积分支付");
    return;
  }
  if(total.value == 0 && totalScore.value  != 0 && index == '2'){
    checked.value = '4';
    Toast("此商品只支持积分支付,请选择积分支付");
    return;
  }
  if(total.value == 0 && totalScore.value  != 0 && index == '3'){
    checked.value = '4';
    Toast("此商品只支持积分支付,请选择积分支付");
    return;
  }
  if(user.value.money == null || user.value.money/100 < total.value){
    if(index == '2'){
      checked.value = '1';
      Toast("您的余额不足，不能使用余额支付");
      return;
    }
  }
  checked.value = index
}



</script>

<style lang="scss" scoped>
.container {
  position: relative;
  height: calc(100vh - 50px) !important;
  overflow-y: scroll;
  &_content {
    padding: 0px 10px 10px 10px;
  }
  &_item {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 15px 10px 15px 10px;
    background: #ffffff;
    border-radius: 6px;
    &_main {
      display: flex;
      flex-direction: row;
      flex: 1;
      &_pic {
        height: 60px;
      }
      &_pic img {
        height: 60px;
      }
      &_info {
        width: 100%;
        display: flex;
        flex-direction: column;
        &_title {
          font-size: 16px;
          color: #212121;
          font-weight: bold;
        }
        &_price {
          margin-top: 10px;
          color: #ff9100;
          font-size: 20px;
          &_tag {
            font-size: 12px;
            color: #9e9e9e;
            margin-left: 10px;
            line-height: 0;
          }
        }
      }
      &_score {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        &_desc {
          color: #ff9100;
          font-size: 10px;
        }
      }
    }
  }
  &_pay {
    margin-top: 10px;
  }
  &_bottom {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    padding: 10px 10px;
  }
}
.container_main_address {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-radius: 6px;
  padding: 24px 15px 24px 15px;
  &_title {
    display: flex;
    flex-direction: row;
    font-size: 12px;
    color: #212121;
  }
  &_title img {
    margin-right: 5px;
    height: 14px;
  }
  &_info {
    margin-top: 20px;
  }
  &_info .van-cell--clickable {
    padding: 0;
  }
}
.van-button--large {
  height: 44px;
}

.van-cell-group--inset {
  margin: 0;
}
.custom-icon {
  margin-top: 3px;
  vertical-align: middle;
  height: 16px;
  margin-right: 4px;
}
.van-image {
  margin: 0 5px 0 5px;
  vertical-align: middle;
}
</style>
