<template>
  <div class="container">
    <div class="container_top">
      <div class="container_top_left">
        <div class="container_top_left_pic">
          <img :src="(user?.avatar!=null||user?.avatar!=='')?user?.avatar:avatar" alt="">
        </div>
        <div class="container_top_left_info">
          <div class="container_top_left_info_name">{{ user?.username }}</div>
          <div class="container_top_left_info_desc"
               v-if="user?.type == 2 || user?.type == 3 || user?.type == 4 || user?.type == 6">
            <div class="container_top_left_info_desc_left">
              {{ user.vipRange ? user.vipRange : "1" }}星 志愿者
            </div>
            <img v-for="(item, i) in starLength" :key="i" src="../../assets/images/center/star.png" alt="">
          </div>
          <div class="container_top_left_info_desc" v-if="user?.type == 1">
            <div class="container_top_left_info_desc_left">
              游客
            </div>
          </div>
        </div>
      </div>
      <div class="container_top_right" @click="toModifyHeader">修改头像</div>
    </div>
    <!--会员信息-->
    <div class="container_member" v-if="user?.type == 2 || user?.type == 3 || user?.type == 4 || user?.type == 6">

      <div class="container_member_time">
        <div class="container_member_timelen"><span
            class="container_member_timelen_tag">{{ user.timeLen ? user.timeLen : "0" }}</span>·小时志愿服务时长
        </div>
        <div class="container_member_nextlevel" v-if="havaNext===true">还差{{ time }}小时升级</div>
      </div>

      <div class="container_member_progress">
        <div class="container_member_progress_bar">
          <van-progress :pivot-text="user.timeLen" color="#FFE9C0" :percentage="per"/>
        </div>
        <div class="container_member_progress_text">{{ user.vipRange ? (user.vipRange + 1) : "1" }}星</div>
      </div>
    </div>
    <div class="container_member_buttons">
      <div class="container_member_buttons_item" @click="toMypub">
        <div class="container_member_buttons_item_icon">
          <img src="../../assets/images/center/mypub.png" alt="">
        </div>
        <div class="container_member_buttons_item_text">我的发布</div>
      </div>

      <div class="container_member_buttons_item" @click="toScoreRecord">
        <div class="container_member_buttons_item_icon">
          <img src="../../assets/images/center/scorerecord.png" alt="">
        </div>
        <div class="container_member_buttons_item_text">积分记录</div>
      </div>

      <div class="container_member_buttons_item" @click="toExchangeRecord">
        <div class="container_member_buttons_item_icon">
          <img src="../../assets/images/center/changerecord.png" alt="">
        </div>
        <div class="container_member_buttons_item_text">兑换记录</div>
      </div>

      <div class="container_member_buttons_item" @click="toRecharge">
        <div class="container_member_buttons_item_icon">
          <img src="../../assets/images/center/recharge.png" alt="">
        </div>
        <div class="container_member_buttons_item_text">余额充值</div>
      </div>

    </div>
    <div class="container_block">
      <van-cell-group inset>
        <van-cell title="消息通知" is-link @click="toMessage">
          <template #icon>
            <img src="../../assets/images/center/message.png" alt="" class="custom-icon">
          </template>
        </van-cell>
        <van-cell title="申请成为志愿者" is-link @click="toApply" v-if="user.type === '1'">
          <template #icon>
            <img src="../../assets/images/center/apply.png" alt="" class="custom-icon">
          </template>
        </van-cell>
        <van-cell title="入驻申请" is-link @click="toApplyToStation" v-if="user.type === '3'">
          <template #icon>
            <img src="../../assets/images/center/apply.png" alt="" class="custom-icon">
          </template>
        </van-cell>
        <van-cell title="申请成为队长" is-link @click="toApplyToCaptain" v-if="user.type === '2'">
          <template #icon>
            <img src="../../assets/images/center/apply.png" alt="" class="custom-icon">
          </template>
        </van-cell>
        <van-cell title="我的会员卡" is-link @click="toMyCard">
          <template #icon>
            <img src="../../assets/images/center/myCard.png" alt="" class="custom-icon">
          </template>
        </van-cell>
      </van-cell-group>
    </div>

    <div class="container_block">
      <van-cell-group inset>
        <van-cell title="邀请注册" is-link @click="toInvite">
          <template #icon>
            <img src="../../assets/images/center/invite.png" alt="" class="custom-icon">
          </template>
        </van-cell>
        <van-cell title="我的参与" is-link @click="toMyJoin">
          <template #icon>
            <img src="../../assets/images/center/join.png" alt="" class="custom-icon">
          </template>
        </van-cell>
        <van-cell title="我的证书" is-link @click="toCertificate">
          <template #icon>
            <img src="../../assets/images/center/prof.png" alt="" class="custom-icon">
          </template>
        </van-cell>
      </van-cell-group>
    </div>
    <div class="container_block">
      <van-cell-group inset>
        <van-cell title="个人资料" is-link @click="toPersonInfo">
          <template #icon>
            <img src="../../assets/images/center/person.png" alt="" class="custom-icon">
          </template>
        </van-cell>
        <van-cell title="收货地址" is-link to="/receiveAddress">
          <template #icon>
            <img src="../../assets/images/center/address.png" alt="" class="custom-icon">
          </template>
        </van-cell>
      </van-cell-group>
    </div>
    <div class="container_block">
      <van-cell-group inset>
        <van-cell title="联系分站" is-link @click="callStation">
          <template #icon>
            <img src="../../assets/images/center/contact.png" alt="" class="custom-icon">
          </template>
        </van-cell>
        <van-cell title="联系总站" is-link @click="callMainStation">
          <template #icon>
            <img src="../../assets/images/center/contact.png" alt="" class="custom-icon">
          </template>
        </van-cell>
      </van-cell-group>
    </div>
    <div class="container_block">
      <van-cell-group inset>
        <van-cell title="退出登录" @click="loginOut">
          <template #icon>
            <img src="../../assets/images/center/contact.png" alt="" class="custom-icon">
          </template>
        </van-cell>
      </van-cell-group>
    </div>
  </div>

  <!--  邀请好友弹出层-->
  <van-popup
      v-model:show="inviteShow"
      round
  >
    <div class="invite_container">
      <img src="../../assets/images/center/invite_register.png" alt="" class="invite_container_img"
           @click="inviteClick" fit>
    </div>
  </van-popup>


</template>
<script setup name="Center">
import {ref} from "@vue/reactivity";
import {useRouter} from "vue-router";
import {getSignature} from "@/api/wxreq";
import {getUserInfo} from "@/api/user";
import wx from "weixin-js-sdk";
import {getLengthUserRange} from "@/api/vipsetting";
import {removeToken} from "@/utils/auth";
import {useStore} from "vuex";
import url from '@/utils/URL'
import {Toast} from "vant";
import {getMainStationInfo,getStationInfo} from "@/api/station";
import {Dialog} from "vant";
const starLength = ref([]);
const avatar = ref('../../assets/images/center/header.png');
const router = useRouter();

const user = ref({})
const time = ref(0)
const havaNext = ref(true)
const per = ref(0)
const inviteShow = ref(false);

const loginOut = () => {
  window.localStorage.clear()
  window.sessionStorage.clear()
  removeToken()
  logoutStation()
  //router.push({path: "/login"})
  window.location.href = url.fontUrl+"#/login";
}
const store = useStore();
const logoutStation = () => {
  store.commit("setStationIds", undefined);
}
const getUser = async () => {
  starLength.value = []
  let response = await getUserInfo();
  user.value = response.data
  for (let i = 1; i <= response.data.vipRange; i++) {
    starLength.value.push(1)
  }
  if(user.value.type != '1'){
    let res = await getLengthUserRange({vipRange: user.value.vipRange + 1});
    havaNext.value = res.data.haveNext;
    if (havaNext.value === false) {
      per.value = 100
    } else {
      per.value = 100 * (1 - (res.data.timeLen - user.value.timeLen) / res.data.timeLen)
    }

    time.value = res.data.timeLen - user.value.timeLen
  }

}
getUser();


const toModifyHeader = () => {
  router.push({path: "modifyHeader"})
}
const toMypub = () => {
  router.push({path: "mypub"})
}
const toCertificate = () => {
  router.push({path: "certificate"})
}
const toScoreRecord = () => {
  router.push({path: "scoreRecord"})
}
const toExchangeRecord = () => {
  router.push({path: "exchangeRecord"})
}
const toRecharge = () => {
  router.push({path: "recharge"})
}
const toPersonInfo = () => {
  router.push({path: "personInfo"})
}

const toMyJoin = () => {
  router.push({path: "myJoin"})
}

const toInvite = async () => {
  const inviteurl = url.inviteUrl + user.value.id;
  let result = await getSignature({url: url.fontUrl})
  const sign = result.data
  wx.config({
    debug: false,
    appId: sign.appid,
    timestamp: sign.timestamp,
    nonceStr: sign.noncestr,
    signature: sign.signature,
    jsApiList: [
      'updateAppMessageShareData',
      'updateTimelineShareData'
    ]
  })
  wx.ready(function () {   //需在用户可能点击分享按钮前就先调用
    wx.updateAppMessageShareData({
      title: '邀请好友', // 分享标题
      desc: '邀请好友得积分', // 分享描述
      link: inviteurl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
      imgUrl: 'https://shuahuo-oss.oss-cn-beijing.aliyuncs.com/20220825/1661410668655137.png', // 分享图标
      success: function () {
        // 设置成功
        inviteShow.value = true;
      }
    })
  });
}

const toMessage = () => {
  router.push({path: "message"})
}

const toApply = () => {
  router.push({path: "apply"})
}

const toApplyToStation = () => {
  router.push({path: "applyToStation"})
}
const toApplyToCaptain = () => {
  router.push({path: "applyToCaptain"})
}
const toMyCard = () => {
  if(user.value.templateId == null){
    Toast("您没有会员卡");
    return;
  }
  router.push({path: "myCard"})
}

//点击了分享
const inviteClick = async () => {
  //todo 替换正式访问地址
  const inviteurl = url.inviteUrl + user.value.id;
  let result = await getSignature({url: url.fontUrl})
  const sign = result.data
  wx.config({
    debug: false,
    appId: sign.appid,
    timestamp: sign.timestamp,
    nonceStr: sign.noncestr,
    signature: sign.signature,
    jsApiList: [
      'updateAppMessageShareData',
      'updateTimelineShareData'
    ]
  })
  wx.ready(function () {   //需在用户可能点击分享按钮前就先调用
    wx.updateAppMessageShareData({
      title: '邀请好友', // 分享标题
      desc: '邀请好友得积分', // 分享描述
      link: inviteurl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
      imgUrl: 'https://shuahuo-oss.oss-cn-beijing.aliyuncs.com/20220825/1661410668655137.png', // 分享图标
      success: function () {
        // 设置成功
        inviteShow.value = false;
        Dialog.alert({
          title: '提示',
          message: '请点击右上角进行分享',
        }).then(() => {
          // on close
        });
      }
    })
  });
}

//点击了联系总站
const callMainStation = async () =>{
  let result = await getMainStationInfo();
  if(result.code === 200){
    let url = 'tel:'+result.data.phone;
    console.error(url);
    window.location.href = url;
  }else {
    Toast("未查询到主站信息");
  }

}

//点击了联系分站
const callStation = async () =>{
  if(store.state.stationId != null && store.state.stationId != 'undefined' && store.state.stationId != 'null'){
    let result = await getStationInfo(store.state.stationId);
    if(result.code === 200){
      let url = 'tel:'+result.data.phone;
      console.error(url);
      window.location.href = url;
    }else {
      Toast("未查询到站点信息");
    }
  }else {
    Toast("请选择分站站点");
  }
}


</script>
<style lang="scss" scoped>
.container {
  height: calc(100vh - 106px) !important;
  overflow-y: scroll;
  padding: 10px 10px 10px 10px;

  &_top {
    padding: 10px 0 10px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &_left {
      display: flex;
      flex-direction: row;
      align-items: center;

      &_pic {
        background: #FFF;
        border-radius: 30px;

        img {
          background: #FFF;
          border-radius: 30px;
          width: 60px;
          height: 60px;
        }
      }

      &_info {
        margin-left: 20px;
        justify-self: center;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        &_name {
          font-size: 16px;
          color: #212121;
          font-weight: bold;
        }

        &_desc {
          margin-top: 11px;
          display: flex;
          align-items: center;

          &_left {
            line-height: 14px;
            color: rgba(33, 33, 33, 100);
            font-size: 12px;
            text-align: left;
            font-family: PingFangSC-regular;
            margin-right: 10px;
          }

          img {
            width: 14px;
            height: 14px;
            margin-left: 5px;
          }
        }

        &_star {
          margin-left: 10px;
        }
      }
    }

    &_right {
      font-size: 13px;
      color: #BDBDBD;
      margin-right: 19px;
    }
  }

  &_member {
    background: #3A3A3C;
    display: flex;
    flex-direction: column;
    padding: 10px 10px;
    border-radius: 8px 8px 0px 0px;

    &_timelen {
      font-size: 10px;
      color: #FFE9C0;

      &_tag {
        font-size: 16px;
        color: #FFE9C0;
        line-height: 16px;
        text-align: left;
        font-family: PingFangSC-semiBold;
      }
    }

    &_time {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &_nextlevel {
      font-size: 10px;
      color: #FFFFFF;
    }

    &_progress {
      display: flex;
      flex-direction: row;
      align-items: center;

      &_bar {
        flex: 1;
        margin-right: 10px;
        margin-top: 8px;
        margin-bottom: 10px;
      }

      &_text {
        font-size: 10px;
        color: #FFFFFF;
      }
    }

    &_buttons {
      background: #FFFFFF;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 20px 24px 20px 24px;
      border-radius: 6px;

      &_item {
        display: flex;
        flex-direction: column;
        align-items: center;

        &_icon {

        }

        &_icon img {
          width: 36px;
        }

        &_text {
          margin-top: 10px;
          font-size: 12px;
          color: #BDBDBD;
        }
      }
    }
  }

  &_block {
    margin-top: 10px;
    border-radius: 6px;
  }
}

.custom-title {
  //font-size: 16px;
  color: #212121;
}

.custom-icon {
  margin-top: 3px;
  vertical-align: middle;
  height: 16px;
  margin-right: 4px;
}

.custom-cell {
  justify-content: center;
  align-items: center;
}

.van-cell-group--inset {
  margin: 0;
}

.invite_container {
  height: 390px;
  overflow: hidden;
  &_img {
    width: 300px;
    height: 390px;
  }
}
</style>
