import Compressor from 'compressorjs';
export default function ImageCompressor(file, backType, quality) {
    return new Promise((resolve, reject) => {
        new Compressor(file, {
			quality:quality || 0.1, //压缩质量
			success(result) {
                if (!backType || backType == 'blob') {
                    resolve(result)
                } else if (backType == 'file') {
                    resolve(result)
                } else {
                    resolve(file)
                }
				// resolve(result);
			},
			error(err) {
                console.log("图片压缩失败");
				reject(err);
			}
		})
    })
}
