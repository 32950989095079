<template>
  <!-- 头部   -->
  <div class="container_content">
    <div class="container_content_top">
      <van-image :src="activity?.picUrl?(activity?.picUrl):require('../assets/images/activity/activity.png')"/>
      <div class="container_content_top_right">
        <div class="container_content_top_right_first">
          <div class="container_content_top_right_first_name">
            {{ activity?.name }}
          </div>
          <div class="container_content_top_right_first_type">
            {{ activity?.activityType?.name }}
          </div>
        </div>
        <div class="container_content_top_right_second">
          <div
              :class="[activity?.status==='0'?'container_content_top_right_second_type3':(activity?.status==='1'?'container_content_top_right_second_type2':(activity?.status==='2'?'container_content_top_right_second_type1':'container_content_top_right_second_type4'))]">
            {{
              activity?.status === '0' ? '已结束' : (activity?.status === '1' ? '进行中' : (activity?.status === '2' ? '报名中' : '未开始'))
            }}
          </div>
          <div class="container_content_top_right_second_par">
          {{activity?.money=='0.00'?'免费活动':'付费活动'}}
          </div>
          <div class="container_content_top_right_second_num">
            <div class="container_content_top_right_second_num_one">
              已报名 <span style=" margin-left: 3px;color: rgba(255, 145, 0, 100);text-align: left;"> {{
                activity.alreaySignUpNum ? activity.alreaySignUpNum : 0
              }} </span>/{{ activity?.limitNum }}人
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="container_content_line"></div>
    <div class="container_content_score">
      <div class="container_content_score_left">
        可{{ activity?.scoreType === '1' ? '获得' : '消耗' }}{{ activity?.score }}积分
      </div>
      <div class="container_content_score_right" v-if="activity?.status!=='0'">
        <img src="../assets/images/activity/details.png" alt="">
        <div class="container_content_score_right_send" @click="onShowShareClick">
          转发
        </div>
      </div>
    </div>
  </div>
  <!-- 中间  -->
  <div class="container_user">
    <div class="container_user_top">
      <div class="container_user_top_left">
        <img src="../assets/images/activity/sponsor.png" alt="">
        <div class="container_user_top_left_address">

          {{ activity?.organizer }}
        </div>
      </div>
      <div class="container_user_top_right">
        主办方
      </div>
    </div>
    <div class="container_user_line"></div>
    <div class="container_user_bottom">
      <div class="container_user_bottom_left">
        {{ activity?.organizerUsername }}
      </div>
      <div class="container_user_bottom_right">
        <img src="../assets/images/activity/phone.png" alt="">
        <div class="container_user_bottom_right_phone">
          {{ activity?.organizerPhone }}
        </div>
      </div>
    </div>
  </div>
  <!--  详细  -->
  <div class="container_details">
    <div class="container_details_address">
      <img src="../assets/images/activity/address.png" alt="">
      <div class="container_details_address_right">
        {{ activity?.addressDetail }}
      </div>
    </div>
    <div class="container_details_content">
      <div class="container_details_content_top">
        <img src="../assets/images/activity/time.png" alt="">
        <div class="container_details_content_top_right">
          活动时间：{{ activity?.starttime }}至{{ activity?.endtime }}
        </div>
      </div>
      <div class="container_details_content_line"></div>
      <div class="container_details_content_description" v-html="activity?.content">
      </div>
      <div class="container_details_content_line"></div>
      <div class="container_details_content_user">
        <van-image round
                   :src="activity?.pubUser?.avatar?(activity?.pubUser?.avatar):require('../assets/images/activity/avatar.png')"/>
        <div class="container_details_content_user_right">
          {{ activity?.pubUser?.username }}发布于{{ activity?.createTime?.substring(0, 10).replaceAll('-', '.') }}
        </div>
      </div>
    </div>
  </div>
  <van-share-sheet
      v-model:show="showShare"
      title="立即分享给好友"
      :options="options"
      @select="onSelect"
  />
  <!-- 自定义表单 -->
  <div class="container_form" v-if="activity?.param!=null&&activity?.param!==''">

    <div class="container_form_item" v-for="(item,index) in JSON.parse(activity?.param)" :key="index">
      <div class="container_form_item_input" v-if="item.type=='input'">
        <div class="container_form_item_input_block">
          <div class="container_form_item_input_block_name">{{ item.name }}</div>
          <div class="container_form_item_input_block_value">{{ item.value }}</div>
        </div>
        <div class="container_form_item_input_line"></div>
      </div>

      <div class="container_form_item_input" v-if="item.type=='textarea'">
        <div class="container_form_item_input_block">
          <div class="container_form_item_input_block_name">{{ item.name }}</div>
          <div class="container_form_item_input_block_value" v-html="(item.value.replace(/\n/g, '<br>'))"></div>
        </div>
        <div class="container_form_item_input_line"></div>
      </div>

      <div class="container_form_item_input" v-if="item.type=='radio'">
        <div class="container_form_item_input_block">
          <div class="container_form_item_input_block_name">{{ item.name }}</div>
          <div class="container_form_item_input_block_value">{{ item.options[item.checked-1].name }}</div>
        </div>
        <div class="container_form_item_input_line"></div>
      </div>
      <div class="container_form_item_input" v-if="item.type=='checkbox'">
        <div class="container_form_item_input_content">
          <div class="container_form_item_input_content_name">{{ item.name }}</div>
          <div class="container_form_item_input_content_value">
            <div class="container_form_item_input_content_value_item" v-for="(v, i) in item.value" :key="i">
              {{ item.options[v - 1].name }}
            </div>
          </div>
        </div>
        <div class="container_form_item_input_line"></div>
      </div>
      <div class="container_form_item_input" v-if="item.type=='date'">
        <div class="container_form_item_input_block">
          <div class="container_form_item_input_block_name">{{ item.name }}</div>
          <div class="container_form_item_input_block_value">{{ item.value }}</div>
        </div>
        <div class="container_form_item_input_line"></div>
      </div>
    </div>
  </div>
</template>

<script setup name="ActivityDetail">
import wx from 'weixin-js-sdk'
import {defineProps, ref,defineEmits} from 'vue';
import {getSignature} from "@/api/wxreq";
import {Dialog} from "vant";
import url from '@/utils/URL'
import {getUserInfo} from "@/api/user";
const emit = defineEmits(["clickShowShare"]);
const props = defineProps({activity: Object});
console.log(props)
const user = ref({})
const showShare = ref(false);
const options = [
  {name: '好友', icon: 'wechat'},
  {
    name: '朋友圈',
    icon: 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.zcool.cn%2Fcommunity%2F018e13586db9c0a8012060c88bb6d6.jpg&refer=http%3A%2F%2Fimg.zcool.cn&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1659148886&t=308e863e90e63e2e6754bc0693c56f32'
  },
];

const onShowShareClick = ()=>{
  emit("clickShowShare")
}

const onSelect = async (option) => {
  const inviteurl = window.location.href;
  let result = await getSignature({url: url.fontUrl})
  const sign = result.data
  wx.config({
    debug: false,
    appId: sign.appid,
    timestamp: sign.timestamp,
    nonceStr: sign.noncestr,
    signature: sign.signature,
    jsApiList: [
       'updateAppMessageShareData',
       'updateTimelineShareData'
    ]
  })
  if (option.name === "好友") {
    wx.ready(function () {   //需在用户可能点击分享按钮前就先调用
      wx.updateAppMessageShareData({
        title: props.value.name ?? "", // 分享标题
        link: inviteurl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
        imgUrl: props.value.picUrl ?? "", // 分享图标
        success: function () {
          // 设置成功
          showShare.value = false;
          Dialog.alert({
            title: '提示',
            message: '请点击右上角进行分享',
          }).then(() => {
            // on close
          });
        }
      })
    });
  }
  if (option.name === "朋友圈") {
    wx.ready(function () {   //需在用户可能点击分享按钮前就先调用
      wx.updateTimelineShareData({
        title: props.value.name ?? "", // 分享标题
        link: inviteurl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
        imgUrl: props.value.picUrl ?? "", // 分享图标
        success: function () {
          // 设置成功
          showShare.value = false;
          Dialog.alert({
            title: '提示',
            message: '请点击右上角进行分享',
          }).then(() => {
            // on close
          });
        }
      })
    });
  }

}


const getUser = async () => {
  let response = await getUserInfo();
  user.value = response.data
}

getUser();
</script>

<style lang="scss" scoped>
.container_content {
  margin-top: 10px;
  width: 355px;
  height: 157px;
  line-height: 20px;
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 100);
  color: rgba(16, 16, 16, 100);
  font-size: 14px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  &_top {
    margin: 10px;
    display: flex;

    .van-image {
      width: 80px;
      height: 80px;
      margin-right: 10px;
    }

    &_right {
      width: 245px;
      height: 80px;
      display: flex;
      flex-direction: column;
      align-items: center;

      &_first {
        display: flex;
        width: 100%;
        height: 48px;

        &_name {
          height: 48px;
          width: 170px;
          color: rgba(33, 33, 33, 100);
          font-size: 14px;
          font-weight: bolder;
          text-align: left;
          font-family: PingFangSC-semiBold;
        }

        &_type {
          margin-left: 21px;
          width: 59px;
          height: 20px;
          line-height: 20px;
          font-size: 12px;
          font-weight: bolder;
          color: rgba(244, 190, 72, 100);
          border-radius: 2px;
          background-color: rgba(244, 190, 72, 0.1);
          text-align: center;
        }
      }

      &_second {
        display: flex;
        width: 100%;
        height: 16px;
        margin-top: 16px;

        &_type1 {
          width: 38px;
          height: 16px;
          line-height: 20px;
          border-radius: 2px;
          background-color: rgba(98, 212, 204, 100);
          color: rgba(255, 255, 255, 100);
          font-size: 10px;
          text-align: left;
          font-weight: bolder;
        }

        &_type2 {
          width: 38px;
          height: 16px;
          line-height: 20px;
          border-radius: 2px;
          background-color: rgba(0, 230, 118, 100);
          color: rgba(255, 255, 255, 100);
          font-size: 10px;
          text-align: left;
          font-weight: bolder;
        }

        &_type3 {
          width: 38px;
          height: 16px;
          line-height: 20px;
          border-radius: 2px;
          background-color: rgba(33, 33, 33, 100);
          color: rgba(255, 255, 255, 100);
          font-size: 10px;
          text-align: left;
          font-weight: bolder;
        }

        &_type4 {
          width: 38px;
          height: 16px;
          line-height: 20px;
          border-radius: 2px;
          background-color: rgba(244, 190, 72, 100);
          color: rgba(255, 255, 255, 100);
          font-size: 10px;
          text-align: left;
          font-weight: bolder;
        }

        &_par {
          margin-left: 10px;
          color: rgba(244, 190, 72, 100);
          font-size: 12px;
          text-align: left;
          font-weight: bolder;
        }

        &_num {
          flex: 1;
          font-size: 12px;
          height: 16px;

          &_one {
            color: rgba(189, 189, 189, 100);
            text-align: right;
          }
        }
      }
    }
  }

  &_line {
    border: 1px solid #F5F5F5;
    width: 335px;
  }

  &_score {
    width: 335px;
    height: 16px;
    line-height: 16px;
    font-size: 14px;
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;

    &_left {
      flex: 1;
      width: 100px;
      text-align: left;
    }

    &_right {
      display: flex;

      img {
        width: 16px;
        height: 16px;
      }

      &_send {
        margin-left: 2px;
        color: rgba(244, 190, 72, 100);
      }
    }
  }
}

.container_user {
  margin-top: 15px;
  width: 355px;
  height: 119px;
  line-height: 20px;
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 100);
  color: rgba(16, 16, 16, 100);
  font-size: 14px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  &_top {
    margin: 10px;
    display: flex;
    height: 40px;
    width: 335px;

    &_left {
      flex: 1;
      display: flex;

      img {
        height: 40px;
        width: 40px;
        border-radius: 25px;
      }

      &_address {
        margin-top: 10px;
        line-height: 20px;
        height: 20px;
        color: rgba(51, 51, 51, 100);
        font-size: 16px;
        text-align: left;
        margin-left: 10px;
        font-weight: bolder;
        font-family: PingFangSC-medium;
      }
    }

    &_right {
      margin-top: 10px;
      line-height: 20px;
      height: 20px;
      color: rgba(189, 189, 189, 100);
      font-size: 14px;
      text-align: left;
      font-family: PingFangSC-regular;
    }
  }

  &_line {
    border: 1px solid #F5F5F5;
    width: 335px;
  }

  &_bottom {
    width: 335px;
    height: 18px;
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;

    &_left {
      color: rgba(51, 51, 51, 100);
      flex: 1;
      text-align: left;
      font-family: PingFangSC-regular;
    }

    &_right {
      display: flex;

      img {
        width: 18px;
        height: 18px;
      }

      &_phone {
        margin-left: 6px;
        color: rgba(51, 51, 51, 100);
        font-weight: bolder;
        font-family: PingFangSC-medium;
      }
    }
  }
}

.container_details {
  width: 355px;
  margin-top: 15px;
  margin-bottom: 20px;
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 100);
  color: rgba(16, 16, 16, 100);
  display: flex;
  flex-direction: column;
  align-items: center;

  &_address {
    height: 54px;
    width: 100%;
    opacity: 0.9;
    background: url("../assets/images/activity/background.png");
    display: flex;

    img {
      width: 18px;
      height: 18px;
      margin: 18px 5px 18px 10px;
      opacity: 1;
      border-radius: 6px 6px 0 0;
    }

    &_right {
      height: 18px;
      opacity: 1;
      color: rgba(255, 145, 0, 100);
      font-size: 14px;
      text-align: left;
      margin-top: 18px;
      margin-bottom: 18px;
      font-family: PingFangSC-semiBold;
      font-weight: bolder;
    }
  }

  &_content {
    width: 335px;

    &_top {
      margin-top: 18px;
      margin-bottom: 18px;
      line-height: 18px;
      width: 335px;
      display: flex;
      align-items: center;
      img {
        width: 18px;
        height: 18px;
        margin-right: 5px;
      }

      &_right {
        color: rgba(33, 33, 33, 100);
        font-size: 14px;
        text-align: left;
        font-family: PingFangSC-semiBold;
        font-weight: bolder;
      }
    }

    &_line {
      border: 1px solid #F5F5F5;
      width: 335px;
    }

    &_description {
      width: 335px;
      text-align: justify;
      font-size: 12px;
      font-family: PingFangSC-regular;
      line-height: 20px;
      margin-top: 15px;
      margin-bottom: 30px;
    }

    &_line {
      border: 1px solid #F5F5F5;
      width: 335px;
    }

    &_user {
      margin-bottom: 15px;
      margin-top: 15px;
      width: 335px;
      height: 25px;
      display: flex;

      .van-image {
        width: 25px;
        height: 25px;
        margin-right: 8px;
        border-radius: 50%;
      }

      &_right {
        height: 25px;
        line-height: 25px;
        color: rgba(185, 190, 204, 100);
        font-size: 12px;
        text-align: left;
        font-family: PingFangSC-regular;
      }
    }
  }
}

.container_form {
  padding-top: 10px;
  padding-bottom: 10px;
  width: 355px;
  line-height: 20px;
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 100);
  color: rgba(16, 16, 16, 100);
  font-size: 14px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;

  &_item {
    width: 355px;
    line-height: 16px;
    font-size: 14px;
    text-align: center;

    &_input {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      &_block {
        width: 91%;
        display: flex;
        align-items: center;
        &_name {

        }
        &_value {
          flex: 1;
          text-align: right;
        }
      }
      &_content {
        width: 91%;
        display: flex;
        align-items: center;

        &_name {

        }

        &_value {
          flex: 1;
          text-align: right;
        }
      }

      &_line {
        margin-top: 10px;
        margin-bottom: 10px;
        border: 1px solid #F5F5F5;
        width: 335px;
      }
    }

  }

}
</style>
