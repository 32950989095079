<template>
  <div class="container">
    <!-- 标题   -->
    <div class="container_title">
      <van-uploader
        accept="image/jpeg,image/png,image/jpg"
        v-model="fileList"
        :max-count="1"
        :after-read="afterRead"
        @delete="deleteImage"
      />
      <div class="container_title_right">
        <img
          src="../../../assets/images/activity/edit.png"
          alt=""
        >
        <div class="container_title_right_input">
          <textarea
            v-model="title"
            rows="3"
            cols="22"
            placeholder="请输入活动标题"
          />
        </div>
      </div>
    </div>
    <!--  活动类别  -->
    <div class="container_type">
      <div class="container_type_item">
        <div class="container_type_item_name">
          活动类别
        </div>
        <div class="container_type_item_right">
          <div class="container_type_item_right_des">
            <van-field
              v-model="typeName"
              placeholder="请选择"
              readonly
              input-align="right"
              @click="typeShow = true"
            />
          </div>
          <img
            src="../../../assets/images/activity/right_edit.png"
            alt=""
          >
        </div>
      </div>
      <div class="container_type_line"></div>

      <div class="container_type_item">
        <div class="container_type_item_name">
          参与人数
        </div>
        <div class="container_type_item_right">
          <div class="container_type_item_right_des">
            <van-field
              v-model="peopleNum"
              input-align="right"
              placeholder="请输入"
              type="number"
            />
          </div>
          <img
            src="../../../assets/images/activity/edit.png"
            alt=""
          >
        </div>
      </div>
    </div>
    <!-- 单位   -->
    <div class="container_type">
      <div class="container_type_item">
        <div class="container_type_item_name">
          主办单位
        </div>
        <div class="container_type_item_right">
          <div class="container_type_item_right_des">
            <van-field
              v-model="hostUnit"
              input-align="right"
              placeholder="请输入"
            />
          </div>
          <img
            src="../../../assets/images/activity/edit.png"
            alt=""
          >
        </div>
      </div>
      <div class="container_type_line"></div>
      <div class="container_type_item">
        <div class="container_type_item_name">
          主办人
        </div>
        <div class="container_type_item_right">
          <div class="container_type_item_right_des">
            <van-field
              v-model="hostUnitPerson"
              input-align="right"
              placeholder="请输入"
            />
          </div>
          <img
            src="../../../assets/images/activity/edit.png"
            alt=""
          >
        </div>
      </div>
      <div class="container_type_line"></div>
      <div class="container_type_item">
        <div class="container_type_item_name">
          主办人电话
        </div>
        <div class="container_type_item_right">
          <div class="container_type_item_right_des">
            <van-field
              v-model="hostUnitPhone"
              input-align="right"
              placeholder="请输入"
              type="tel"
            />
          </div>
          <img
            src="../../../assets/images/activity/edit.png"
            alt=""
          >
        </div>
      </div>
    </div>
    <!-- four   -->
    <div class="container_type">
      <div class="container_type_item">
        <div class="container_type_item_name">
          承办单位
        </div>
        <div class="container_type_item_right">
          <div class="container_type_item_right_des">
            <van-field
              v-model="undertakeUnit"
              input-align="right"
              placeholder="请输入"
            />
          </div>
          <img
            src="../../../assets/images/activity/right_edit.png"
            alt=""
          >
        </div>
      </div>
      <div class="container_type_line"></div>
      <div class="container_type_item">
        <div class="container_type_item_name">
          承办人
        </div>
        <div class="container_type_item_right">
          <div class="container_type_item_right_des">
            <van-field
              v-model="undertakeUnitPerson"
              input-align="right"
              placeholder="请输入"
            />
          </div>
          <img
            src="../../../assets/images/activity/right_edit.png"
            alt=""
          >
        </div>
      </div>
      <div class="container_type_line"></div>
      <div class="container_type_item">
        <div class="container_type_item_name">
          承办人电话
        </div>
        <div class="container_type_item_right">
          <div class="container_type_item_right_des">
            <van-field
              v-model="undertakeUnitPhone"
              input-align="right"
              placeholder="请输入"
              type="tel"
            />
          </div>
          <img
            src="../../../assets/images/activity/right_edit.png"
            alt=""
          >
        </div>
      </div>
    </div>
    <!--  five  -->
    <div class="container_type">
      <div class="container_type_item">
        <div class="container_type_item_name">
          活动费用
        </div>
        <div class="container_type_item_right">
          <div class="container_type_item_right_des">
            <van-field
              v-model="moneyName"
              placeholder="0"
              input-align="right"
              type="number"
            />
          </div>
          <img
            src="../../../assets/images/activity/edit.png"
            alt=""
          >
        </div>
      </div>
      <div class="container_type_line"></div>
      <div class="container_type_item">
        <div class="container_type_item_name">
          积分类型
        </div>
        <div class="container_type_item_right">
          <div class="container_type_item_right_des">
            <van-field
              v-model="scoreTypeName"
              placeholder="请选择"
              readonly
              input-align="right"
              @click="scoreTypeShow = true"
            />
          </div>
          <img
            src="../../../assets/images/activity/right_edit.png"
            alt=""
          >
        </div>
      </div>
      <div class="container_type_line"></div>
      <div class="container_type_item">
        <div class="container_type_item_name">
          积分数值
        </div>
        <div class="container_type_item_right">
          <div class="container_type_item_right_des">
            <van-field
              v-model="score"
              input-align="right"
              placeholder="0"
              type="number"
            />

          </div>
          <img
            src="../../../assets/images/activity/edit.png"
            alt=""
          >
        </div>
      </div>
    </div>
    <!--  six  -->
    <div class="container_type">
      <div class="container_type_item">
        <div class="container_type_item_name">
          开始时间
        </div>
        <div class="container_type_item_right">
          <div class="container_type_item_right_des">
            <van-field
              v-model="startTime"
              placeholder="请选择"
              readonly
              @click="startTimeShow = true"
              input-align="right"
            />
          </div>
          <img
            src="../../../assets/images/activity/right_edit.png"
            alt=""
          >
        </div>
      </div>
      <div class="container_type_line"></div>
      <div class="container_type_item">
        <div class="container_type_item_name">
          结束时间
        </div>
        <div class="container_type_item_right">
          <div class="container_type_item_right_des">
            <van-field
              v-model="endTime"
              placeholder="请选择"
              readonly
              @click="endTimeShow = true"
              input-align="right"
            />
          </div>
          <img
            src="../../../assets/images/activity/right_edit.png"
            alt=""
          >
        </div>
      </div>
      <div class="container_type_line"></div>
      <div class="container_type_item">
        <div class="container_type_item_name">
          报名截止
        </div>
        <div class="container_type_item_right">
          <div class="container_type_item_right_des">
            <van-field
                v-model="signEndTime"
                placeholder="请选择"
                readonly
                input-align="right"
                @click="signEndTimeShow = true"
            />
          </div>
          <img
              src="../../../assets/images/activity/right_edit.png"
              alt=""
          >
        </div>
      </div>
      <div class="container_type_line"></div>
      <div class="container_type_item">
        <div class="container_type_item_name">
          活动地点
        </div>
        <div class="container_type_item_right">
          <div class="container_type_item_right_des">
            <van-field
              v-model="addressName"
              placeholder="请选择"
              readonly
              @click="addressShow = true"
              input-align="right"
            />
          </div>
          <img
            src="../../../assets/images/activity/right_edit.png"
            alt=""
          >
        </div>
      </div>
      <div class="container_type_line"></div>
      <div
        class="container_type_item"
        @click="showMap=true"
      >
        <div class="container_type_item_name">
          详细地址
        </div>
        <div class="container_type_item_right">
          <div class="container_type_item_right_des">
            <van-field
              v-model="address"
              placeholder="请选择"
              input-align="right"
              readonly
            />
          </div>
          <img
            src="../../../assets/images/activity/edit.png"
            alt=""
          >
        </div>
      </div>
      <div class="container_type_line"></div>
      <div class="container_type_item">
        <div class="container_type_item_name">
          通知类型
        </div>
        <div class="container_type_item_right">
          <div class="container_type_item_right_des">
            <van-field
              v-model="noticeTypeName"
              placeholder="请选择"
              readonly
              input-align="right"
              @click="noticeTypeShow = true"
            />
          </div>
          <img
            src="../../../assets/images/activity/right_edit.png"
            alt=""
          >
        </div>
      </div>
    </div>
    <!-- seven 声明   -->
    <div class="container_declaration">
      <div class="container_declaration_top">
        <img
          src="../../../assets/images/activity/edit.png"
          alt=""
        >
        <div class="container_declaration_top_name">
          输入活动说明
        </div>
      </div>
      <div class="container_declaration_input">
        <van-field
          v-model="content"
          rows="3"
          autosize
          type="textarea"
          maxlength="500"
          placeholder="请输入"
          show-word-limit
        />
      </div>
    </div>
    <!-- eight 时间   -->
    <div class="container_type">
      <div class="container_type_item">
        <div class="container_type_item_name">
          长期活动
        </div>
        <div class="container_type_item_right">
          <div class="container_type_item_right_des">
            <van-switch
              v-model="isLong"
              size="16px"
              active-color="#FF9100"
              inactive-color="#F5F5F5"
              @click="clickSwitch"
            />
          </div>
        </div>
      </div>
<!--      <div
        v-if="isLong"
        class="container_type_line"
      ></div>
      <div
        v-if="isLong"
        class="container_type_item"
      >
        <div class="container_type_item_name">
          活动周期
        </div>
        <div class="container_type_item_right">
          <div class="container_type_item_right_des">
            <van-tag
              :class="[item.checked===true?'van-background-checked':'van-background-default']"
              v-for="(item,i) in period"
              :key="i"
              @click="choosePeriod(item.id)"
            >{{ item.name }}
            </van-tag>
          </div>
          <img
            src="../../../assets/images/activity/right_edit.png"
            alt=""
          >
        </div>
      </div>
      <div
        v-if="isLong"
        class="container_type_line"
      ></div>
      <div
        v-if="isLong"
        class="container_type_item"
      >
        <div class="container_type_item_name">
          重复时间
        </div>
        <div class="container_type_item_right">
          <div class="container_type_item_right_des">
            <van-field
              v-if="periodId==='1'"
              v-model="repeatMonth"
              placeholder="请选择"
              readonly
              @click="showTime=true"
              input-align="right"
            />
            <van-field
              v-if="periodId==='2'"
              v-model="repeatWeek"
              placeholder="请选择"
              readonly
              @click="showTime=true"
              input-align="right"
            />
            &lt;!&ndash;            <van-field
                v-if="periodId==='3'"
                v-model="repeatDay"
                placeholder="请选择"
                readonly
                @click="dayShow = true"
                input-align="right"
            />&ndash;&gt;
          </div>
          <img
            src="../../../assets/images/activity/right_edit.png"
            alt=""
          >
        </div>
      </div>-->
    </div>
    <!-- 添加子活动   -->
    <div class="container_type" @click="addData" v-if="isLong">
      <div class="container_type_add">
        <img src="../../../assets/images/activity/addChild.png" alt="">
        <div class="container_type_add_add">
          添加子活动
        </div>
      </div>
    </div>
    <div class="container_type" v-for="(item,index) in childList" :key="index">
      <div class="container_type_item">
        <div class="container_type_item_name">
          子活动-{{index+1}}
        </div>
        <div class="container_type_item_right" @click="delData">
         <span style="color: #FD7F04">删除</span>
        </div>
      </div>
      <div class="container_type_line"></div>
      <div class="container_type_item">
        <div class="container_type_item_name">
          开始时间
        </div>
        <div class="container_type_item_right">
          <div class="container_type_item_right_des">
            <van-field
                v-model="item.starttime"
                placeholder="请选择"
                readonly
                @click="clickChildStartTime(index,true)"
                input-align="right"
            />
          </div>
          <img
              src="../../../assets/images/activity/right_edit.png"
              alt=""
          >
        </div>
      </div>
      <div class="container_type_line"></div>
      <div class="container_type_item">
        <div class="container_type_item_name">
          结束时间
        </div>
        <div class="container_type_item_right">
          <div class="container_type_item_right_des">
            <van-field
                v-model="item.endtime"
                placeholder="请选择"
                readonly
                @click="clickChildEndTime(index,true)"
                input-align="right"
            />
          </div>
          <img
              src="../../../assets/images/activity/right_edit.png"
              alt=""
          >
        </div>
      </div>
      <div class="container_type_line"></div>
      <div class="container_type_item">
        <div class="container_type_item_name">
          报名截止
        </div>
        <div class="container_type_item_right">
          <div class="container_type_item_right_des">
            <van-field
                v-model="item.signEndTime"
                placeholder="请选择"
                readonly
                input-align="right"
                @click="clickChildSignEndTime(index,true)"
            />
          </div>
          <img
              src="../../../assets/images/activity/right_edit.png"
              alt=""
          >
        </div>
      </div>
    </div>
    <!-- nine 自定义字段  -->
    <div
      class="container_customer"
      @click="showCustomPop"
    >
      <div><span><img
            src="../../../assets/images/calenders.png"
            alt=""
          ></span><span>添加自定义字段</span></div>
    </div>

    <div
      class="container_drags"
      v-if="btnLists.length>0"
    >
      <draggable
        :list="btnLists"
        ghost-class="ghost"
        handle=".move"
        filter=".forbid"
        chosen-class="chosenClass"
        animation="300"
        @start="onStart"
        @end="onEnd"
        :touch-start-threshold="50"
        :move="onMove"
      >
        <template #item="{ element,index }">
          <div
            class="container_drags_item move"
            v-if="element.type==='input'"
          >
            <van-field
              v-model="element.value"
              :label="element.name"
              :placeholder="'请输入'+element.name"
            /><span
              style="width:50px;color: #FFBF1E;"
              @click="changeColName(element,index)"
            >编辑</span>
            <span
              style="width:50px;color: #FFBF1E;"
              @click="deleteByIndex(element,index)"
            >删除</span>
          </div>
          <div
            class="container_drags_item move"
            v-else-if="element.type==='textarea'"
          >
            <van-field
              v-model="element.value"
              rows="2"
              autosize
              :label="element.name"
              type="textarea"
              :placeholder="'请输入'+element.name"
            />
            <span
              style="width:50px;color: #FFBF1E;"
              @click="changeColName(element,index)"
            >编辑</span>
            <span
              style="width:50px;color: #FFBF1E;"
              @click="deleteByIndex(element,index)"
            >删除</span>
          </div>

          <div
            class="container_drags_item move"
            v-else-if="element.type==='radio'"
          >
            <van-field
              :name="element.name"
              :label="element.name"
            >
              <template #input>
                <van-radio-group
                  v-model="element.value"
                  direction="horizontal"
                >
                  <van-radio
                    :name="option.value"
                    v-for="option in element.options"
                    :key="option"
                  >{{option.name}}</van-radio>

                </van-radio-group>
              </template>
            </van-field>
            <span
              style="width:50px;color: #FFBF1E;"
              @click="changeColNameAndOption(element,index)"
            >编辑</span>
            <span
              style="width:50px;color: #FFBF1E;"
              @click="deleteByIndex(element,index)"
            >删除</span>
          </div>
          <div
            class="container_drags_item move"
            v-else-if="element.type==='checkbox'"
          >
            <van-field
              :name="element.name"
              :label="element.name"
            >
              <template #input>
                <van-checkbox-group
                  v-model="element.value"
                  direction="horizontal"
                >
                  <van-checkbox
                    :name="option.value"
                    v-for="option in element.options"
                    :key="option"
                  >{{option.name}}</van-checkbox>

                </van-checkbox-group>
              </template>
            </van-field>
            <span
              style="width:50px;color: #FFBF1E;"
              @click="changeColNameAndOption(element,index)"
            >编辑</span>
            <span
              style="width:50px;color: #FFBF1E;"
              @click="deleteByIndex(element,index)"
            >删除</span>
          </div>

          <div
            class="container_drags_item move"
            v-else-if="element.type==='date'"
          >
            <van-field
              v-model="element.value"
              is-link
              readonly
              name="datetimePicker"
              :label="element.name"
              :placeholder="'点击选择'+element.name"
              @click="showPicker = true"
            />
            <span
              style="width:50px;color: #FFBF1E;"
              @click="changeColName(element,index)"
            >编辑</span>
            <span
              style="width:50px;color: #FFBF1E;"
              @click="deleteByIndex(element,index)"
            >删除</span>
            <van-popup
              v-model:show="showPicker"
              position="bottom"
            >
              <van-datetime-picker
                type="date"
                @confirm="res=>onConfirm(element,res)"
                @cancel="showPicker = false"
              />
            </van-popup>
          </div>

        </template>
      </draggable>
    </div>

  </div>
  <!-- 底部按钮 -->
  <div class="container_bottom">
    <van-button @click="submit">发布活动</van-button>
  </div>

  <!-- 弹出层   -->

  <van-action-sheet
    v-model:show="showCustom"
    title="选择自定义字段的类型"
  >
    <div class="popcustom">
      <div class="popcustom_title"></div>
      <div class="popcustom_btns">
        <div
          class="popcustom_btn"
          v-for="(item,index) in btnList"
          :key="index"
          @click="addToDrag(item)"
        >{{item.name}}</div>
      </div>
    </div>
  </van-action-sheet>

  <van-popup
    v-model:show="showModal"
    closeable
    close-icon="close"
    round
  >
    <div class="Colmodal">
      <div class="Colmodal_input">
        <van-field
          v-model="titlevalue"
          label="字段名称"
          placeholder="请输入字段名称"
        />
      </div>
      <div class="Colmodal_btn">
        <span>
          <van-button
            plain
            type="default"
            @click="cancleChange"
          >取消</van-button>
        </span> <span>
          <van-button
            type="primary"
            @click="confirmChange"
          >确定</van-button>
        </span>
      </div>

    </div>

  </van-popup>

  <van-popup
    v-model:show="showMutiModal"
    closeable
    close-icon="close"
    round
  >
    <div class="Colmodal">
      <div class="Colmodal_input">
        <van-field
          v-model="titlevalue"
          label="字段名称"
          placeholder="请输入字段名称"
        />
      </div>
      <div
        class="Colmodal_options"
        v-for="op in optionList"
        :key="op"
      >
        <van-field
          v-model="op.name"
          label="选项名"
          placeholder="请输入选项名"
        />

        <van-field
          v-model="op.value"
          label="选项值"
          placeholder="请输入选项值"
        />
      </div>
      <div class="Colmodal_btn">
        <span>
          <van-button
            plain
            type="default"
            @click="cancleMutiChange"
          >取消</van-button>
        </span>
        <span>
          <van-button
            type="primary"
            @click="addOption"
          >添加选项</van-button>
        </span>
        <span>
          <van-button
            type="primary"
            @click="confirmMutiChange"
          >确定</van-button>
        </span>
      </div>

    </div>

  </van-popup>

  <van-popup
    v-model:show="showMap"
    position="bottom"
    :lock-scroll="false"
    :safe-area-inset-bottom="true"
    :style="{ height: '100%' }"
  >
    <ChooseMap
      @goBack="closeShowMap"
      @toMap="toMap"
    ></ChooseMap>
  </van-popup>

  <van-popup
    v-model:show="showTime"
    position="bottom"
    :lock-scroll="false"
    :safe-area-inset-bottom="true"
    :style="{ height: '100%' }"
  >
    <TimeByDate
      :id="periodId"
      @goBack="closeShowTime"
      @toSignup="toSignup"
    ></TimeByDate>
  </van-popup>

  <van-popup
    v-model:show="dayShow"
    position="bottom"
    :style="{ height: '40%' }"
  >
    <van-datetime-picker
      type="time"
      @cancel="dayShow = false"
      @confirm="confirmPickerDay"
    />
  </van-popup>

  <van-popup
    v-model:show="typeShow"
    position="bottom"
    :style="{ height: '35%' }"
  >
    <van-picker
      title="活动类型"
      :columns="typeColumns"
      @confirm="onConfirmType"
      @cancel="typeShow=false"
    />
  </van-popup>

  <van-popup
    v-model:show="stationShow"
    position="bottom"
    :style="{ height: '35%' }"
  >
    <van-picker
      title="报名限制"
      :columns="stationColumns"
      @confirm="onConfirmStation"
      @cancel="stationShow=false"
    />
  </van-popup>

  <van-popup
    v-model:show="scoreTypeShow"
    position="bottom"
    :style="{ height: '35%' }"
  >
    <van-picker
      title="积分类型"
      :columns="scoreTypeColumns"
      @confirm="onConfirmScoreType"
      @cancel="scoreTypeShow=false"
    />
  </van-popup>
  <van-popup
    v-model:show="noticeTypeShow"
    position="bottom"
    :style="{ height: '35%' }"
  >
    <van-picker
      title="通知方式"
      :columns="noticeTypeColumns"
      @confirm="onConfirmNoticeType"
      @cancel="noticeTypeShow=false"
    />
  </van-popup>
  <van-popup
    v-model:show="moneyShow"
    position="bottom"
    :style="{ height: '35%' }"
  >
    <van-picker
      title="活动费用"
      :columns="moneyColumns"
      @confirm="onConfirmMoney"
      @cancel="moneyShow=false"
    />
  </van-popup>

  <van-popup
    v-model:show="startTimeShow"
    position="bottom"
    :style="{ height: '35%' }"
  >
    <van-datetime-picker
      v-model="currentDateStart"
      type="datetime"
      @cancel="startTimeShow = false"
      @confirm="confirmPickerStart"
    />
  </van-popup>
  <van-popup
    v-model:show="childStartTimeShow"
    position="bottom"
    :style="{ height: '35%' }"
  >
    <van-datetime-picker
      v-model="currentDateStart"
      type="datetime"
      @cancel="childStartTimeShow = false"
      @confirm="confirmPickerStartChild"
    />
  </van-popup>

  <van-popup
    v-model:show="endTimeShow"
    position="bottom"
    :style="{ height: '35%' }"
  >
    <van-datetime-picker
      v-model="currentDateEnd"
      type="datetime"
      @cancel="endTimeShow = false"
      @confirm="confirmPickerEnd"
    />
  </van-popup>
  <van-popup
    v-model:show="childEndTimeShow"
    position="bottom"
    :style="{ height: '35%' }"
  >
    <van-datetime-picker
      v-model="currentDateEnd"
      type="datetime"
      @cancel="childEndTimeShow = false"
      @confirm="confirmPickerEndChild"
    />
  </van-popup>
  <van-popup
    v-model:show="signEndTimeShow"
    position="bottom"
    :style="{ height: '35%' }"
  >
    <van-datetime-picker
        v-model="currentDateSign"
        type="datetime"
        @cancel="signEndTimeShow = false"
        @confirm="confirmPickerSignEnd"
    />
  </van-popup>
  <van-popup
    v-model:show="childSignEndTimeShow"
    position="bottom"
    :style="{ height: '35%' }"
  >
    <van-datetime-picker
        v-model="currentDateSign"
        type="datetime"
        @cancel="childSignEndTimeShow = false"
        @confirm="confirmPickerSignEndTimeChild"
    />
  </van-popup>
  <!-- 地区 -->
  <van-popup
    v-model:show="addressShow"
    position="bottom"
    :style="{ height: '35%' }"
  >
    <van-area
      title="活动地点"
      :area-list="areaList"
      value=""
      @cancel="addressShow = false"
      @confirm="addressConfirm"
    />
  </van-popup>

</template>

<script setup>
import { ref } from "vue";
import { useRouter } from "vue-router";
import { getActivityTypeList, getTypeScore, issueActivity } from "@/api/activity";
import { uploadFiles } from "@/api/upload";
import { Toast } from "vant";
import TimeByDate from "@/views/activity/issueActivity/TimeByDate";
import ChooseMap from "@/views/activity/issueActivity/ChooseMap";
import { areaList } from '@vant/area-data';
import draggable from 'vuedraggable';
import ImageCompressor from "@/utils/ImageCompressor";

//子活动列表
const childList=ref([])
const childShow=ref(false)

const clickSwitch=()=>{
  if(!isLong.value){
    childList.value=[]
  }
}
const addData=()=>{
  childShow.value=true
  childList.value.push({
    starttime: '',
    endtime: '',
    signEndTime: ''
  })
}
const delData=(index)=>{
  childList.value.splice(index,1)
  if(childList.value.length==0){
    childShow.value=false
  }
}
const childStartTimeShow=ref(false)
const childEndTimeShow=ref(false)
const childSignEndTimeShow=ref(false)
const indexItem=ref(0)
const clickChildStartTime=(index,val)=>{
  childStartTimeShow.value=val
  indexItem.value=index
}
const clickChildEndTime=(index,val)=>{
  childEndTimeShow.value=val
  indexItem.value=index
}
const clickChildSignEndTime=(index,val)=>{
  childSignEndTimeShow.value=val
  indexItem.value=index
}
//点选坐标

const showMap = ref(false);
const lng = ref('');
const lat = ref('');
const closeShowMap = () => {
  showMap.value = false
}
const toMap = (val) => {
  lng.value = val.lng
  lat.value = val.lat
  address.value = val.addressDetail
  showMap.value = false
}

const showPicker = ref(false);
const showCustom = ref(false)
const showModal = ref(false)
const showMutiModal = ref(false)
const changeIndex = ref(0)
const router = useRouter()
const titlevalue = ref("")
const btnList = ref([{ id: 1, name: "单行文本", type: 'input', value: "" }, { id: 2, name: "多行文本", type: 'textarea', value: "" }, { id: 3, name: "单选", type: 'radio', value: "", checked: "1", options: [{ name: "男", value: "1" }, { name: "女", value: "2" }] }, { id: 4, name: "多选", type: 'checkbox', value: [], options: [{ name: "java", value: "1" }, { name: "php", value: "2" }, { name: "python", value: "3" }] }, { id: 5, name: "日期", type: 'date', value: "" }])
const btnLists = ref([])
const optionList = ref([])

const deleteByIndex = (item, index) => {
  btnLists.value.splice(index, 1)
}

const onConfirm = (item, value) => {
  const date = new Date(value)
  const v = formatteTime(date)
  item.value = v
  showPicker.value = false
}

const formatteTime = (date) => {
  var y = date.getFullYear()
  var m = date.getMonth() + 1
  m = m < 10 ? '0' + m : m
  var d = date.getDate()
  d = d < 10 ? '0' + d : d
  var h = date.getHours()
  h = h < 10 ? '0' + h : h
  var minute = date.getMinutes()
  minute = minute < 10 ? '0' + minute : minute
  var second = date.getSeconds()
  second = second < 10 ? '0' + second : second
  return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second
}

const addOption = () => {
  btnLists.value[changeIndex.value].options.push({ name: "", value: "" })
}

const confirmChange = () => {
  showModal.value = false
  btnLists.value[changeIndex.value].name = titlevalue.value
}

const confirmMutiChange = () => {
  showMutiModal.value = false
  btnLists.value[changeIndex.value].name = titlevalue.value
}

const cancleMutiChange = () => {
  showMutiModal.value = false
}

const cancleChange = () => {
  showModal.value = false
}

const addToDrag = (item) => {
  let json = JSON.stringify(item)
  let newitem = JSON.parse(json)
  btnLists.value.push(newitem)
  showCustom.value = false
  console.log(btnLists.value)
}

const changeColNameAndOption = (item, index) => {
  titlevalue.value = item.name
  showMutiModal.value = true
  console.log(item, index)
  changeIndex.value = index
  optionList.value = item.options
}

const changeColName = (item, index) => {
  titlevalue.value = item.name
  showModal.value = true
  console.log(item, index)
  changeIndex.value = index
}
const onMove = (e) => {
  //不允许停靠
  if (e.relatedContext.element.disabledPark == true) return false;
  return true;
}
const onStart = () => {
  console.log("开始拖拽")
}
const onEnd = () => {
  console.log("结束拖拽")
}

const showCustomPop = () => {
  showCustom.value = true
}

const submit = async () => {
  if (!title.value) {
    Toast({
      message: '请输入活动标题',
      icon: 'none',
      duration: 2000
    })
    return false
  }
  if (files.value.length === 0) {
    Toast({
      message: '请上传活动封面',
      icon: 'none',
      duration: 2000
    })
    return false
  }
  if (!typeId.value) {
    Toast({
      message: '请选择活动类别',
      icon: 'none',
      duration: 2000
    })
    return false
  }

  if (!peopleNum.value) {
    Toast({
      message: '请输入参与人数',
      icon: 'none',
      duration: 2000
    })
    return false
  }
  if (!hostUnit.value) {
    Toast({
      message: '请输入主办单位',
      icon: 'none',
      duration: 2000
    })
    return false
  }
  if (!hostUnitPerson.value) {
    Toast({
      message: '请输入主办人',
      icon: 'none',
      duration: 2000
    })
    return false
  }
  if (!hostUnitPhone.value) {
    Toast({
      message: '请输入主办人电话',
      icon: 'none',
      duration: 2000
    })
    return false
  }
  if (!undertakeUnit.value) {
    Toast({
      message: '请输入协办单位',
      icon: 'none',
      duration: 2000
    })
    return false
  }
  if (!undertakeUnitPerson.value) {
    Toast({
      message: '请输入协办人',
      icon: 'none',
      duration: 2000
    })
    return false
  }
  if (!undertakeUnitPhone.value) {
    Toast({
      message: '请输入协办人电话',
      icon: 'none',
      duration: 2000
    })
    return false
  }
  if (!startTime.value) {
    Toast({
      message: '请选择开始时间',
      icon: 'none',
      duration: 2000
    })
    return false
  }
  if (!endTime.value) {
    Toast({
      message: '请选择结束时间',
      icon: 'none',
      duration: 2000
    })
    return false
  }
  if (!signEndTime.value) {
    Toast({
      message: '请选择报名截止时间',
      icon: 'none',
      duration: 2000
    })
    return false
  }
  if (!addressName.value) {
    Toast({
      message: '请选择活动地点',
      icon: 'none',
      duration: 2000
    })
    return false
  }
  if (!address.value) {
    Toast({
      message: '请输入详细地址',
      icon: 'none',
      duration: 2000
    })
    return false
  }
  if (!content.value) {
    Toast({
      message: '请输入活动内容',
      icon: 'none',
      duration: 2000
    })
    return false
  }
  if (isLong.value) {
    if(childList.value.length==0){
      Toast({
        message: '至少添加一个子活动',
        icon: 'none',
        duration: 2000
      })
      return false
    }
    /*if (!periodId.value) {
      Toast({
        message: '请选择活动周期',
        icon: 'none',
        duration: 2000
      })
      return false
    }

    if (periodId.value === "1") {
      if (!repeatMonth.value) {
        Toast({
          message: '请选择重复时间',
          icon: 'none',
          duration: 2000
        })
        return false
      }
    }
    if (periodId.value === "2") {
      if (!repeatWeek.value) {
        Toast({
          message: '请选择重复时间',
          icon: 'none',
          duration: 2000
        })
        return false
      }
    }*/
  }
  let data = {
    name: title.value,
    picUrl: files.value[0],
    actTypeId: typeId.value,
    limitNum: peopleNum.value,
    organizer: hostUnit.value,
    organizerUsername: hostUnitPerson.value,
    organizerPhone: hostUnitPhone.value,
    coOrganiser: undertakeUnit.value,
    coOrganizerUsername: undertakeUnitPerson.value,
    coOrganizerPhone: undertakeUnitPhone.value,
    money: (moneyName.value === '' ? "0" : moneyName.value),
    scoreType: scoreTypeId.value,
    score: (score.value === '' ? "0" : score.value),
    starttime: startTime.value,
    endtime: endTime.value,
    signEndTime: signEndTime.value,
    address: addressName.value,
    addressDetail: address.value,
    lng: lng.value,
    lat: lat.value,
    content: content.value,
    noticeType: noticeTypeId.value,
  }
  if(btnLists.value.length==0){
    data.param=JSON.stringify(btnLists.value)
  }
  if (isLong.value) {
    data.longType = "1";
    data.childActivityList=childList.value
    /*data.cycle = periodId.value
    data.repeatTime = repeatTime.value*/
  } else {
    data.longType = "0";
  }
  let response = await issueActivity(data);
  if (response) {
    Toast({
      message: '发布成功',
      icon: 'success',
      duration: 2000
    })
    router.push({
      path: "/Activity",
    });
  }
}

//长期活动
const isLong = ref(false);
//活动周期
/*const period = ref([
  { id: '1', name: '月', checked: true },
  { id: '2', name: '周' },
]);*/
const periodId = ref('1');
/*const choosePeriod = (val) => {
  console.log(val)
  for (let i = 0; i < period.value.length; i++) {
    if (period.value[i].id === val) {
      period.value[i].checked = true;//当前点击的位置为true即选中
      periodId.value = period.value[i].id
    } else {
      period.value[i].checked = false;//其他的位置为false
    }
  }
  console.log(period.value)
};*/
//重复时间

const showTime = ref(false);
const repeatMonth = ref('');
const repeatWeek = ref('');
const repeatTime = ref('');
const repeatTimeLabel = ref([]);
const closeShowTime = () => {
  showTime.value = false
}
const toSignup = (val) => {
  repeatTime.value = val.toString();
  repeatTimeLabel.value = val;
  if (periodId.value === '1') {
    isLong.value = true;
    let arr = []
    for (const e of repeatTimeLabel.value) {
      arr.push(e + "日")
    }
    let s = arr.join(",");
    repeatMonth.value = "每月 " + s;
  }

  if (periodId.value === '2') {
    isLong.value = true;
    let arr = []
    for (const e of repeatTimeLabel.value) {

      arr.push('周' + e)
    }
    let s = arr.join(",");
    repeatWeek.value = "每周 " + s;
  }
  showTime.value = false
}



//活动周期-天
const repeatDay = ref('');

const dayShow = ref(false);
const confirmPickerDay = (val) => {
  repeatDay.value = val
  dayShow.value = false
}





//活动说明
const content = ref('');


const addressShow = ref(false);
const addressName = ref('');
const provinceId = ref(null);
const cityId = ref(null);
const areaId = ref(null);
const addressConfirm = (val) => {
  if (val.length > 0) {
    if (val.length === 1) {
      provinceId.value = val[0].code
      addressName.value = val[0].name
    } else if (val.length === 2) {
      provinceId.value = val[0].code
      cityId.value = val[1].code
      addressName.value = val[0].name + "-" + val[1].name
    } else {
      provinceId.value = val[0].code
      cityId.value = val[1].code
      areaId.value = val[2].code
      addressName.value = val[0].name + "-" + val[1].name + "-" + val[2].name
    }
    addressShow.value = false
  }
}
//活动类别
const getAcTypeList = async () => {
  let response = await getActivityTypeList();
  typeColumns.value = response.data
  if (typeColumns.value.length > 0) {
    for (let e of typeColumns.value) {
      e.text = e.name
    }
  }
}
getAcTypeList();
const typeShow = ref(false);
const typeId = ref(null);
const typeName = ref('');
const scoreEdit = ref(true);
const typeColumns = ref([]);
const onConfirmType = async (val) => {
  typeName.value = val.name
  typeId.value = val.id
  typeShow.value = false
  let response = await getTypeScore(typeId.value);
  if (response.data.score == null) {
    //输入框
    scoreEdit.value = true
    score.value = ''
  } else {
    //选择
    score.value = response.data.score
    scoreEdit.value = false
  }
}
//报名限制
const stationShow = ref(false);
const stationName = ref('');
const stationId = ref(null);
const stationColumns = ref([
  { id: 1, name: '总站报名', text: "总站报名" },
  { id: 2, name: '分站报名', text: "分站报名" }
]);
const onConfirmStation = (val) => {
  stationName.value = val.name
  stationId.value = val.id
  stationShow.value = false
}
//积分设置
const score = ref('');
//活动标题
const title = ref('');
//封面图片
const fileList = ref([]);
const files = ref([]);
const deleteImage = (file, detail) => {
  files.value.splice(detail.index, 1)
}
const afterRead = async (file) => {
  // 此时可以自行将文件上传至服务器
  let blob = await ImageCompressor(file.file, 'blob', 0.1);
  const formData = new FormData();
  let newFile = new File([blob],file.file.name,{type: file.file.type, lastModified: Date.now()})
  formData.append('file', newFile)
  let response = await uploadFiles(formData);
  files.value.push(response.url)
}
/*const beforeRead = (file) => {
  if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg') {
    return true
  }
  Toast('请上传图片')
  return false
}*/
//主办单位
const hostUnit = ref('');
const hostUnitPerson = ref('');
const hostUnitPhone = ref('');
//承办单位
const undertakeUnit = ref('');
const undertakeUnitPerson = ref('');
const undertakeUnitPhone = ref('');
//参与人数
const peopleNum = ref('');

//活动费用
const moneyShow = ref(false);
const moneyName = ref('');

//积分类型
const scoreTypeShow = ref(false);
const scoreTypeName = ref('获得');
const scoreTypeId = ref(1);
const scoreTypeColumns = ref([
  { id: 1, name: '获得', text: "获得" },
  { id: 2, name: '消耗', text: "消耗" }
]);
const onConfirmScoreType = (val) => {
  scoreTypeName.value = val.name
  scoreTypeId.value = val.id
  scoreTypeShow.value = false
}

//通知方式
const noticeTypeShow = ref(false);
const noticeTypeName = ref('无');
const noticeTypeId = ref(0);
const noticeTypeColumns = ref([
  { id: 0, name: '无', text: "无" },
  { id: 1, name: '短信', text: "短信" },
  { id: 2, name: '微信', text: "微信" },
]);


//报名截止
const signEndTime = ref('');
const signEndTimeShow = ref(false);

const onConfirmNoticeType = (val) => {
  noticeTypeName.value = val.name
  noticeTypeId.value = val.id
  noticeTypeShow.value = false
}

//开始日期
const startTime = ref('');
const startTimeShow = ref(false);
const currentDateStart = ref(new Date())
const confirmPickerStart = (val) => {
  const year = val.getFullYear() // 年
  let month = val.getMonth() + 1 // 月
  if (month >= 1 && month <= 9) {
    month = `0${month}`
  }
  let date = val.getDate()  // 日
  if (date >= 1 && date <= 9) {
    date = `0${date}`
  }
  let hour=val.getHours()
  if (hour >= 0 && hour <= 9) {
    hour = `0${hour}`
  }
  let minutes=val.getMinutes()
  if (minutes >= 0 && minutes <= 9) {
    minutes = `0${minutes}`
  }
  startTime.value = `${year}-${month}-${date} ${hour}:${minutes}`
  startTimeShow.value = false
}
const confirmPickerStartChild = (val) => {
  const year = val.getFullYear() // 年
  let month = val.getMonth() + 1 // 月
  if (month >= 1 && month <= 9) {
    month = `0${month}`
  }
  let date = val.getDate()  // 日
  if (date >= 1 && date <= 9) {
    date = `0${date}`
  }
  let hour=val.getHours()
  if (hour >= 0 && hour <= 9) {
    hour = `0${hour}`
  }
  let minutes=val.getMinutes()
  if (minutes >= 0 && minutes <= 9) {
    minutes = `0${minutes}`
  }
  childList.value[indexItem.value].starttime = `${year}-${month}-${date} ${hour}:${minutes}`
  childStartTimeShow.value = false
}
const confirmPickerEndChild = (val) => {
  const year = val.getFullYear() // 年
  let month = val.getMonth() + 1 // 月
  if (month >= 1 && month <= 9) {
    month = `0${month}`
  }
  let date = val.getDate()  // 日
  if (date >= 1 && date <= 9) {
    date = `0${date}`
  }
  let hour=val.getHours()
  if (hour >= 0 && hour <= 9) {
    hour = `0${hour}`
  }
  let minutes=val.getMinutes()
  if (minutes >= 0 && minutes <= 9) {
    minutes = `0${minutes}`
  }
  childList.value[indexItem.value].endtime = `${year}-${month}-${date} ${hour}:${minutes}`
  childEndTimeShow.value = false
}
const confirmPickerSignEndTimeChild = (val) => {
  const year = val.getFullYear() // 年
  let month = val.getMonth() + 1 // 月
  if (month >= 1 && month <= 9) {
    month = `0${month}`
  }
  let date = val.getDate()  // 日
  if (date >= 1 && date <= 9) {
    date = `0${date}`
  }
  let hour=val.getHours()
  if (hour >= 0 && hour <= 9) {
    hour = `0${hour}`
  }
  let minutes=val.getMinutes()
  if (minutes >= 0 && minutes <= 9) {
    minutes = `0${minutes}`
  }
  childList.value[indexItem.value].signEndTime = `${year}-${month}-${date} ${hour}:${minutes}`
  childSignEndTimeShow.value = false
}
//结束日期
const endTime = ref('');
const endTimeShow = ref(false);
const currentDateEnd = ref(new Date())
const currentDateSign = ref(new Date())
const confirmPickerEnd = (val) => {
  const year = val.getFullYear() // 年
  let month = val.getMonth() + 1 // 月
  if (month >= 1 && month <= 9) {
    month = `0${month}`
  }
  let date = val.getDate()  // 日
  if (date >= 1 && date <= 9) {
    date = `0${date}`
  }
  let hour=val.getHours()
  if (hour >= 0 && hour <= 9) {
    hour = `0${hour}`
  }
  let minutes=val.getMinutes()
  if (minutes >= 0 && minutes <= 9) {
    minutes = `0${minutes}`
  }
  endTime.value = `${year}-${month}-${date} ${hour}:${minutes}`
  endTimeShow.value = false
}
const confirmPickerSignEnd = (val) => {
  const year = val.getFullYear() // 年
  let month = val.getMonth() + 1 // 月
  if (month >= 1 && month <= 9) {
    month = `0${month}`
  }
  let date = val.getDate()  // 日
  if (date >= 1 && date <= 9) {
    date = `0${date}`
  }
  let hour=val.getHours()
  if (hour >= 0 && hour <= 9) {
    hour = `0${hour}`
  }
  let minutes=val.getMinutes()
  if (minutes >= 0 && minutes <= 9) {
    minutes = `0${minutes}`
  }
  signEndTime.value = `${year}-${month}-${date} ${hour}:${minutes}`
  signEndTimeShow.value = false
}

//详细地址
const address = ref('');

</script>

<style lang="scss" scoped>
.Colmodal {
  border-radius: 8px;
  width: 250px;
  padding: 30px 30px;
  &_options {
    //display: flex;
  }
  &_input {
    margin-bottom: 30px;
  }
  &_btn {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
}
.popcustom {
  padding: 20px 20px;
  &_title {
    text-align: center;
  }
  &_btns {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 30px;
    justify-content: space-between;
  }
  &_btn {
    margin-right: 5px;
    margin-left: 5px;
    margin-bottom: 10px;
    width: 30%;
    padding: 12px 24px;
    border-radius: 4px;
    border: 1px solid rgba(238, 238, 238, 100);
    text-align: center;
  }
}
.checkbox-btn {
  display: flex;
  padding: 10px 10px 10px 10px;
  &_left {
    width: 60px;
    height: 30px;
    border-radius: 4px;
    background-color: rgba(255, 145, 0, 100);
    color: rgba(255, 255, 255, 100);
    font-size: 14px;
    text-align: center;
    font-family: Microsoft Yahei;
  }
  &_right {
    margin-left: 235px;
    width: 60px;
    height: 30px;
    border-radius: 4px;
    background-color: rgba(255, 145, 0, 100);
    color: rgba(255, 255, 255, 100);
    font-size: 14px;
    text-align: center;
    font-family: Microsoft Yahei;
  }
}
.van-background-default {
  background-color: rgba(245, 245, 245, 100);
  color: rgba(189, 189, 189, 100);
}

.van-background-checked {
  background-color: rgba(255, 145, 0, 100);
  color: rgba(255, 255, 255, 100);
}

.container {
  position: relative;
  height: calc(100vh - 130px) !important;
  overflow-y: scroll;
  padding: 10px 10px 10px 10px;
  &_drags {
    margin-top: 15px;
    padding: 16px 16px;
    background-color: rgba(255, 255, 255, 100);
    &_item {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
  &_customer {
    margin-top: 15px;
    padding: 16px 16px;
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 100);
    display: flex;
    justify-content: center;
    img {
      height: 16px;
      vertical-align: middle;
      margin-right: 6px;
    }
  }

  &_title {
    line-height: 20px;
    border-radius: 6px;
    background-color: rgba(255, 255, 255, 100);
    color: rgba(16, 16, 16, 100);
    font-size: 14px;
    text-align: center;
    font-family: Arial;
    display: flex;

    .van-uploader {
      margin-left: 10px;
      margin-top: 10px;

      .van-uploader__upload {
        background-color: rgba(255, 255, 255, 100);
        border: 1px solid rgba(238, 238, 238, 100);
      }

      .van-uploader__upload-icon {
        font-size: 20px;
        margin-bottom: 20px;
      }
    }

    &_right {
      display: flex;

      img {
        width: 16px;
        height: 16px;
        margin-top: 19px;
      }

      &_input {
        margin-left: 5px;
        margin-top: 15px;
        height: 24px;
        color: rgba(189, 189, 189, 100);
        font-size: 18px;
        text-align: left;
        font-family: PingFangSC-medium;

        textarea {
          width: 100%;
          border: none;
          background: none;
          color: rgba(33, 33, 33, 100);
          font-size: 16px;
          text-align: left;
          font-family: PingFangSC-medium;
          resize: none;
        }

        textarea::placeholder {
          color: rgba(189, 189, 189, 100);
          font-size: 18px;
          text-align: left;
          font-family: PingFangSC-medium;
          font-weight: 700;
        }
      }
    }
  }

  &_type {
    margin-top: 10px;
    line-height: 20px;
    border-radius: 6px;
    background-color: rgba(255, 255, 255, 100);
    color: rgba(16, 16, 16, 100);
    font-size: 14px;
    text-align: center;
    font-family: Arial;
    display: flex;
    flex-direction: column;
    padding: 18px 10px 18px 10px;

    &_add {
      display: flex;
      align-items: center;
      img {
        width: 16px;
        height: 16px;
      }
      &_add {
        margin-left: 5px;
        line-height: 18px;
        color: rgba(66, 66, 66, 100);
        font-size: 14px;
        text-align: left;
        font-family: PingFangSC-regular
      }
    }
    &_item {
      display: flex;
      img{
        width: 16px;
        height: 16px;
      }
      &_add{
        margin-left: 5px;
        line-height: 18px;
        color: rgba(66, 66, 66, 100);
        font-size: 14px;
        text-align: left;
        font-family: PingFangSC-regular
      }
      &_name {
        height: 18px;
        color: rgba(33, 33, 33, 100);
        font-size: 16px;
        text-align: left;
        font-family: PingFangSC-regular;
        flex: 1;
      }

      &_right {
        height: 18px;
        display: flex;
        align-items: center;

        &_des {
          line-height: 18px;
          color: rgba(33, 33, 33, 100);
          font-size: 16px;
          text-align: right;
          font-family: PingFangSC-medium;
          font-weight: bolder;

          .van-tag {
            margin-left: 12px;
            height: 30px;
            width: 25px;
            border-radius: 4px;
            font-size: 14px;
            font-family: Microsoft Yahei;
            padding-left: 15px;
          }
        }

        img {
          width: 14px;
          height: 14px;
        }
      }
    }

    &_item2 {
      display: flex;

      &_name {
        height: 18px;
        color: rgba(189, 189, 189, 100);
        font-size: 16px;
        text-align: left;
        font-family: PingFangSC-regular;
        flex: 1;
      }

      &_right {
        height: 18px;
        display: flex;
        align-items: center;

        &_des {
          text-align: right;
          color: rgba(189, 189, 189, 100);
          font-size: 16px;
          text-align: right;
          font-family: PingFangSC-regular;
          margin-right: 19px;
        }

        img {
          width: 14px;
          height: 14px;
        }
      }
    }

    &_line {
      margin-top: 18px;
      margin-bottom: 18px;
      border: 1px solid #f5f5f5;
      width: 335px;
    }
  }

  &_declaration {
    margin-top: 10px;
    line-height: 20px;
    border-radius: 6px;
    background-color: rgba(255, 255, 255, 100);
    color: rgba(16, 16, 16, 100);
    font-size: 14px;
    font-family: Arial;
    padding: 18px 10px 18px 10px;
    display: flex;
    flex-direction: column;

    &_top {
      display: flex;
      align-items: center;

      img {
        width: 14px;
        height: 14px;
        color: rgba(33, 33, 33, 100);
      }

      &_name {
        margin-left: 5px;
        line-height: 18px;
        color: rgba(33, 33, 33, 100);
        font-size: 16px;
        text-align: left;
        font-family: PingFangSC-regular;
      }
    }

    &_input {
    }
  }
}

.container_bottom {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 64px;
  line-height: 20px;
  background-color: rgba(255, 255, 255, 100);
  color: rgba(16, 16, 16, 100);
  font-size: 14px;
  text-align: center;
  box-shadow: 0px -1px 1px 0px rgba(238, 238, 238, 100);
  font-family: Arial;

  .van-button {
    margin: 10px;
    width: 355px;
    height: 44px;
    border-radius: 6px;
    background-color: #ffbf1e;
    text-align: center;
    color: rgba(255, 255, 255, 100);
    font-size: 14px;
    font-family: PingFangSC-semiBold;
    font-weight: bolder;
  }
}
</style>
