<template>
  <div class="container">
    <div class="container_month">
      <div class="container_month_top">
        <div class="container_month_top_left">服务项目</div>
        <div class="container_month_top_right">&nbsp;&nbsp;(多选)&nbsp;&nbsp;</div>
      </div>
      <van-checkbox-group v-model="periodChecked">
        <div v-for="(item,index) in periodColumns" :key="index">
          <div class="container_month_line"></div>
          <van-checkbox icon-size="14px" checked-color="#FFBF1E" shape="square" :name="item.name">{{
              item.name
            }}
          </van-checkbox>
        </div>
      </van-checkbox-group>
    </div>

  </div>

  <!--  按钮  -->
  <div class="container_bottom">
    <div class="container_bottom_visit" @click="toBack7">
      取消
    </div>
    <van-button @click="toChoosePeriod7">确定</van-button>
  </div>
</template>

<script setup>
import {defineEmits, ref} from "vue";
import {getUserInfo} from "@/api/user";

const emit = defineEmits(['toChoosePeriod7'])

const getInfo = async () => {
  let result = await getUserInfo();
  if(result.data.periodList===null){
    periodChecked.value=[]
  }else {
    periodChecked.value = result.data.periodList.weekSun
  }
}
getInfo()

const periodColumns = ref([
  {id: 1, name: '08:00-12:00', text: "08:00-12:00"},
  {id: 2, name: '12:00-14:00', text: "12:00-14:00"},
  {id: 3, name: '14:00-18:00', text: "14:00-18:00"},
  {id: 4, name: '18:00-21:00', text: "18:00-21:00"},
])
const periodChecked = ref([]);

const toBack7 = () => {
  emit('toBack7', false)
}
const toChoosePeriod7 = () => {

  emit('toChoosePeriod7', periodChecked.value)
}
</script>

<style lang="scss" scoped>
.container {
  padding: 10px;
  height: calc(100vh - 120px) !important;
  overflow-y: scroll;

  &_month {
    margin-top: 10px;
    line-height: 20px;
    border-radius: 6px;
    background-color: rgba(255, 255, 255, 100);
    color: rgba(16, 16, 16, 100);
    font-size: 14px;
    text-align: center;
    font-family: Arial;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 18px 10px 18px 10px;

    &_top {
      display: flex;
      text-align: left;
      line-height: 18px;
      width: 335px;

      &_left {
        color: rgba(33, 33, 33, 100);
        font-size: 16px;
        text-align: left;
        font-family: PingFangSC-regular;
      }

      &_right {
        color: rgba(158, 158, 158, 100);
        font-size: 16px;
        text-align: left;
        font-family: PingFangSC-regular;
      }
    }

    &_line {
      margin-top: 18px;
      border: 1px solid #F5F5F5;
      width: 335px;
    }

    .van-checkbox-group {
      .van-checkbox {
        margin-top: 18px;
      }
    }
  }

  &_week {
    margin-top: 10px;
    line-height: 20px;
    border-radius: 6px;
    background-color: rgba(255, 255, 255, 100);
    color: rgba(16, 16, 16, 100);
    font-size: 14px;
    text-align: center;
    font-family: Arial;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 18px 10px 18px 10px;

    &_top {
      display: flex;
      text-align: left;
      line-height: 18px;
      width: 335px;

      &_left {
        color: rgba(33, 33, 33, 100);
        font-size: 16px;
        text-align: left;
        font-family: PingFangSC-regular;
      }

      &_right {
        color: rgba(158, 158, 158, 100);
        font-size: 16px;
        text-align: left;
        font-family: PingFangSC-regular;
      }
    }

    &_line {
      margin-top: 18px;
      border: 1px solid #F5F5F5;
      width: 335px;
    }

    .van-checkbox-group {
      .van-checkbox {
        margin-top: 18px;
      }
    }
  }

  &_tag {
    margin-top: 20px;
    margin-bottom: 20px;
    line-height: 14px;
    color: rgba(97, 97, 97, 100);
    font-size: 14px;
    text-align: left;
    font-family: PingFangSC-regular;
  }
}

.container_bottom {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 64px;
  background-color: rgba(255, 255, 255, 100);
  color: rgba(16, 16, 16, 100);
  font-size: 14px;
  text-align: center;
  box-shadow: 0px -1px 1px 0px rgba(238, 238, 238, 100);
  font-family: Arial;
  display: flex;

  &_visit {
    margin-top: 22px;
    margin-bottom: 22px;
    margin-left: 56px;
    color: rgba(244, 190, 72, 100);
    font-size: 16px;
    text-align: left;
    font-weight: bolder;
    font-family: PingFangSC-medium;
  }

  .van-button {
    margin-left: 56px;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 220px;
    height: 44px;
    border-radius: 6px;
    background-color: #FFBF1E;
    text-align: center;
    color: rgba(255, 255, 255, 100);
    font-size: 14px;
    font-family: PingFangSC-semiBold;
  }
}
</style>
