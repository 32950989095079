<template>
  <div class="container">
    <div
      class="container_content"
      :style="{backgroundImage: 'url('+imgUrl+')',backgroundSize: '290px 408px'}"
    >
      <!-- <div class="container_content"> -->
      <div
        style="text-align: left"
        v-for="(item,index) in cert.profArr"
        :key="index"
        :style="[{position:'absolute'},{top: item.h5pos.top},{left: item.h5pos.left},{width: '200px'}]"
      >
        {{item.name==="文字描述" ? "" : item.name}} :
        <span v-if="item.colname==='cardnum'">
          {{item.cardnum}}
        </span>
        <span v-else-if="item.colname==='truename'">
          {{item.truename}}
        </span>
        <span v-else-if="item.colname==='info'">
          {{item.info}}
        </span>
        <span v-else-if="item.colname==='company'">
          {{item.company}}
        </span>
        <span v-else-if="item.colname==='creatTime'">
          {{item.creatTime}}
        </span>
      </div>
      <!-- <img :src="imgUrl" width="300" height="450"/> -->
      <!-- <div class="container_content_platform">
        山东阳光志愿者服务平台
      </div>
      <div class="container_content_certificate">
        荣誉证书
      </div>
      <div class="container_content_name">
        {{ cert.detail.order1 }}
      </div>
      <div class="container_content_des">
        {{ cert.detail.order2 }}
      </div>
      <div class="container_content_line"></div>
      <div class="container_content_address">{{ cert.detail.order3 }}</div>
      <div class="container_content_time">{{ cert.detail.order4}}</div> -->

    </div>
    <div class="container_num">
      {{ cert.detail.order5}}
    </div>
  </div>
</template>

<script setup name="CertificateDetails">
import { reactive, ref, toRefs } from "@vue/reactivity";
import { useRoute } from "vue-router";
import { getCertification } from "../../api/certificate";
const route = useRoute();
const data = reactive({ cert: {} })
console.log(route.query.id);
//const imgUrl = ref('../../assets/images/center/certificate_back.png');
const imgUrl = ref("");
const getCertDetail = async () => {
  let result = await getCertification(route.query.id);
  data.cert = result.data
  console.log(result)
  if (result.data.detail.bgImgUrl != null) {
    imgUrl.value = result.data.detail.bgImgUrl;
  }
}
const { cert } = toRefs(data)
getCertDetail();
</script>

<style lang="scss" scoped>
.container {
  // line-height: 20px;
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 100);
  color: rgba(16, 16, 16, 100);
  font-size: 14px;
  text-align: center;
  font-family: Arial;
  margin-left: 10px;
  margin-top: 10px;
  margin-right: 10px;
  height: 100%;

  // display: flex;
  // flex-direction: column;
  // align-items: center;
  padding: 25px 25px 10px 25px;
  &_content {
    //background-image: url("../../assets/images/center/certificate_back.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 30px 20px 35px 20px;
    height: 100%;
    margin: 0 auto;
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    position: relative;
    &_platform {
      line-height: 18px;
      color: rgba(66, 66, 66, 100);
      font-size: 14px;
      text-align: center;
      font-family: SourceHanSerif-medium;
    }
    &_certificate {
      margin-top: 30px;
      line-height: 48px;
      color: rgba(173, 132, 39, 100);
      font-size: 48px;
      text-align: center;
      font-family: SourceHanSerif-black;
    }
    &_name {
      margin-top: 30px;
      line-height: 20px;
      color: rgba(33, 33, 33, 100);
      font-size: 20px;
      text-align: center;
      font-family: SourceHanSerif-black;
      font-weight: bolder;
    }
    &_des {
      margin-top: 29px;
      line-height: 30px;
      color: rgba(33, 33, 33, 100);
      font-size: 12px;
      text-align: justify;
      font-family: SourceHanSerif-black;
      font-weight: bolder;
    }
    &_line {
      margin-top: 15px;
      width: 205px;
      height: 1px;
      line-height: 20px;
      background-color: rgba(33, 33, 33, 100);
      text-align: center;
    }
    &_certify {
      width: 100%;
      margin-top: 15px;
      line-height: 20px;
      color: rgba(33, 33, 33, 100);
      font-size: 12px;
      text-align: left;
      font-family: SourceHanSerif-black;
      font-weight: bolder;
    }

    &_address {
      width: 100%;
      margin-top: 42px;
      line-height: 12px;
      color: rgba(117, 117, 117, 100);
      font-size: 12px;
      text-align: right;
      font-family: SourceHanSerif-black;
      font-weight: bolder;
    }
    &_time {
      width: 100%;
      margin-top: 8px;
      line-height: 10px;
      color: rgba(117, 117, 117, 100);
      font-size: 10px;
      text-align: right;
      font-family: SourceHanSerif-medium;
    }
  }
  &_num {
    margin-top: 20px;
    line-height: 10px;
    color: rgba(33, 33, 33, 100);
    font-size: 10px;
    text-align: center;
    font-family: SourceHanSerif-medium;
  }
}
</style>
