<template>
  <div class="container">
    <ActivityDetail :activity="activity" @clickShowShare = "clickShowShare"></ActivityDetail>

    <!--  按钮  -->
    <div class="container_bottom">
      <div class="container_bottom_left">
        <div class="container_bottom_left_one" @click="toIndex">
        <img src="https://shuahuo-oss.oss-cn-beijing.aliyuncs.com/volun/home.png" alt="">
        <div class="container_bottom_visits" > 返回首页 </div>    
       </div>
       <div  class="container_bottom_left_two">
        <img src="../../../assets/images/activity/visit.png" alt="">
        <div class="container_bottom_visit" @click="showShare = true">
          邀请好友
        </div>
       </div>

      </div>

      <div class="container_bottom_right">
        <van-button  @click="toSignup" :disabled="check==true||activity?.status!='2'">{{signType==='0'?'已报名未审核':(signType==='1'?'已报名':(signType==='2'?'报名未通过':'我要报名'))}}</van-button>

      </div>
      </div>
  </div>
  <!--  邀请好友弹出层-->
  <van-popup
      v-model:show="inviteShow"
      round
  >
    <div class="invite_container">
      <img src="../../../assets/images/center/invite_register.png" alt="" class="invite_container_img" @click="inviteClick">
    </div>
  </van-popup>



  <van-share-sheet
      v-model:show="showShare"
      title="立即分享给好友"
      :options="options"
      @select="onSelect"
  />


</template>

<script setup>
import {useRoute, useRouter} from "vue-router";
import ActivityDetail from "@/components/ActivityDetail";
import url from '@/utils/URL'
const router = useRouter();
import {checkSignUp, getDetail} from "@/api/activity";
import {ref} from "vue";
import {getSignature} from "@/api/wxreq";
import wx from "weixin-js-sdk";
import {getUserInfo} from "@/api/user";
import {Dialog} from "vant";
const activity=ref({});
const route = useRoute();
const id = route.query.id;
const showShare = ref(false);
console.log(id)

const clickShowShare = ()=>{
  showShare.value = true
}

const options = [
  {name: '好友', icon: 'wechat'},
  {
    name: '朋友圈',
    icon: 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.zcool.cn%2Fcommunity%2F018e13586db9c0a8012060c88bb6d6.jpg&refer=http%3A%2F%2Fimg.zcool.cn&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1659148886&t=308e863e90e63e2e6754bc0693c56f32'
  },
];

const toIndex = ()=>{
  router.push({
    path: "/"
  });
}

const onSelect = async (option) => {
  const inviteurl = window.location.href;
  let result = await getSignature({url: url.fontUrl})
  const sign = result.data
   let str = activity.value.content.replace(/<.*?>/g,"")
    let content = "";
    if(str.length>=30){
        content = str.substr(0,30);
    }else{
      content = str;
    }
  wx.config({
    debug: false,
    appId: sign.appid,
    timestamp: sign.timestamp,
    nonceStr: sign.noncestr,
    signature: sign.signature,
    jsApiList: [
       'updateAppMessageShareData',
       'updateTimelineShareData'
    ]
  })

  if (option.name === "好友") {
   
    wx.ready(function () {   //需在用户可能点击分享按钮前就先调用
      wx.updateAppMessageShareData({
        title: activity.value.name ?? "", // 分享标题
        link: inviteurl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
        imgUrl: activity.value.picUrl ?? "", // 分享图标
        desc: content,
        success: function () {
          // 设置成功
          showShare.value = false;
          Dialog.alert({
            title: '提示',
            message: '请点击右上角进行分享',
          }).then(() => {
            // on close
          });
        },
        
      })
    });
  }
  if (option.name === "朋友圈") {
    wx.ready(function () {   //需在用户可能点击分享按钮前就先调用
      wx.updateTimelineShareData({
        title: activity.value.name ?? "", // 分享标题
        link: inviteurl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
        imgUrl: activity.value.picUrl ?? "", // 分享图标
         desc: content,
        success: function () {
          // 设置成功
          showShare.value = false;
          Dialog.alert({
            title: '提示',
            message: '请点击右上角进行分享',
          }).then(() => {
            // on close
          });
        }
      })
    });
  }

}

const  toSignup=()=> {
  router.push({
    path: "/signup",
    query: {
      id:id
    },
  });
}

const inviteShow = ref(false);
// const toInvite = async () =>{
//   //todo 替换正式访问地址
//   const inviteurl = url.inviteUrl + user.value.id;
//   let result = await getSignature({url: url.fontUrl})
//   const sign = result.data
//   wx.config({
//     debug: false,
//     appId: sign.appid,
//     timestamp: sign.timestamp,
//     nonceStr: sign.noncestr,
//     signature: sign.signature,
//     jsApiList: [
//       'updateAppMessageShareData',
//       'updateTimelineShareData'
//     ]
//   })
//   wx.ready(function () {   //需在用户可能点击分享按钮前就先调用
//     wx.updateAppMessageShareData({
//       title: '邀请好友', // 分享标题
//       desc: '邀请好友得积分', // 分享描述
//       link: inviteurl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
//       imgUrl: '../assets/images/center/invite.png', // 分享图标
//       success: function () {
//         // 设置成功
//         inviteShow.value= true;
//       }
//     })
//   });

// }
const user=ref({})
const check=ref(true)
const signType=ref('3')
const getUser=async()=>{
  let response=await getUserInfo();
  user.value=response.data
}
const getCheckSignUp=async()=>{
  let response=await checkSignUp({id});
  check.value=response.data.isSign
  if(check.value===true){
    signType.value=response.data.signType
  }

}
getCheckSignUp()
getUser();
//点击了分享
const inviteClick = async () => {
  //todo 替换正式访问地址
  const inviteurl = url.inviteUrl + user.value.id;
  let result = await getSignature({url: url.fontUrl})
  const sign = result.data
  wx.config({
    debug: false,
    appId: sign.appid,
    timestamp: sign.timestamp,
    nonceStr: sign.noncestr,
    signature: sign.signature,
    jsApiList: [
      'updateAppMessageShareData',
      'updateTimelineShareData'
    ]
  })
  wx.ready(function () {   //需在用户可能点击分享按钮前就先调用
    wx.updateAppMessageShareData({
      title: '邀请好友', // 分享标题
      desc: '邀请好友得积分', // 分享描述
      link: inviteurl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
      imgUrl: '../assets/images/center/invite.png', // 分享图标
      success: function () {
        // 设置成功
        inviteShow.value = false;
      }
    })
  });
}

const getAcDetail= async ()=>{
  let response=await getDetail(id);
  activity.value=response.data
 /* activity.value.starttime=activity.value.starttime.replace('-','年');
  activity.value.starttime=activity.value.starttime.replace('-','月')+'日';
  activity.value.endtime=activity.value.endtime.replace('-','年');
  activity.value.endtime=activity.value.endtime.replace('-','月')+'日';*/
}
getAcDetail();
</script>

<style lang="scss" scoped>
.invite_container{
  &_img{
    width: 300px;
    height: 400px;
  }
}
  .container {
    height: calc(100vh - 100px)!important;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
    &_content{
      margin-top: 10px;
      width: 355px;
      height: 157px;
      line-height: 20px;
      border-radius: 6px;
      background-color: rgba(255, 255, 255, 100);
      color: rgba(16, 16, 16, 100);
      font-size: 14px;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      &_top{
        margin: 10px;
        display: flex;
        img{
          width: 80px;
          height: 80px;
          margin-right: 10px;
        }
        &_right{
          width: 245px;
          height: 80px;
          display: flex;
          flex-direction: column;
          align-items: center;
          &_first{
            display: flex;
            width: 100%;
            height: 48px;
            &_name{
              height: 48px;
              width: 170px;
              color: rgba(33, 33, 33, 100);
              font-size: 14px;
              font-weight: bolder;
              text-align: left;
              font-family: PingFangSC-semiBold;
            }
            &_type{
              margin-left: 21px;
              width: 59px;
              height: 20px;
              line-height: 20px;
              font-size: 12px;
              font-weight: bolder;
              color: rgba(244, 190, 72, 100);
              border-radius: 2px;
              background-color: rgba(244, 190, 72, 0.1);
              text-align: center;
            }
          }
          &_second{
            display: flex;
            width: 100%;
            height: 16px;
            margin-top: 16px;
            &_type{
              width: 38px;
              height: 16px;
              line-height: 20px;
              border-radius: 2px;
              background-color: rgba(98, 212, 204, 100);
              color: rgba(255, 255, 255, 100);
              font-size: 10px;
              text-align: left;
              font-weight: bolder;
            }
            &_par{
              margin-left: 10px;
              color: rgba(244, 190, 72, 100);
              font-size: 12px;
              text-align: left;
              font-weight: bolder;
            }
            &_num{
              display: flex;
              margin-left: 55px;
              width: 94px;
              font-size: 12px;
              height: 16px;
              &_one{
                color: rgba(189, 189, 189, 100);

                text-align: left;
              }
              &_two{
                margin-left: 3px;
                color: rgba(255, 145, 0, 100);
                text-align: left;
              }
              &_three{
                margin-left: 2px;
                color: rgba(189, 189, 189, 100);
                text-align: left;
              }
            }
          }
        }
      }
      &_line{
        border: 1px solid #F5F5F5;
        width: 335px;
      }
      &_score{
        width: 335px;
        height: 16px;
        line-height: 16px;
        font-size: 14px;
        display: flex;
        margin-top: 20px;
        margin-bottom: 20px;
        &_left{
          flex: 1;
          width: 100px;
          text-align: left;
        }
        &_right{
          display: flex;
          img{
            width: 16px;
            height: 16px;
          }
          &_send{
            margin-left: 2px;
            color: rgba(244, 190, 72, 100);
          }
        }
      }
    }
    &_user{
      margin-top: 15px;
      width: 355px;
      height: 119px;
      line-height: 20px;
      border-radius: 6px;
      background-color: rgba(255, 255, 255, 100);
      color: rgba(16, 16, 16, 100);
      font-size: 14px;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      &_top{
        margin: 10px;
        display: flex;
        height: 40px;
        width: 335px;
        &_left{
          flex: 1;
          display: flex;
          img{
            height: 40px;
            width: 40px;
            border-radius: 25px;
          }
          &_address{
            margin-top: 10px;
            line-height: 20px;
            height: 20px;
            color: rgba(51, 51, 51, 100);
            font-size: 16px;
            text-align: left;
            margin-left: 10px;
            font-weight: bolder;
            font-family: PingFangSC-medium;
          }
        }
        &_right{
          margin-top: 10px;
          line-height: 20px;
          height: 20px;
          color: rgba(189, 189, 189, 100);
          font-size: 14px;
          text-align: left;
          font-family: PingFangSC-regular;
        }
      }
      &_line{
        border: 1px solid #F5F5F5;
        width: 335px;
      }
      &_bottom{
        width: 335px;
        height: 18px;
        font-size: 16px;
        margin-top: 20px;
        margin-bottom: 20px;
        display: flex;
        &_left{
          color: rgba(51, 51, 51, 100);
          flex: 1;
          text-align: left;
          font-family: PingFangSC-regular;
        }
        &_right{
          display: flex;
          img{
            width: 18px;
            height: 18px;
          }
          &_phone{
            margin-left: 6px;
            color: rgba(51, 51, 51, 100);
            font-weight: bolder;
            font-family: PingFangSC-medium;
          }
        }
      }
    }
    &_details{
      width: 355px;
      margin-top: 15px;
      margin-bottom: 50px;
      border-radius: 6px;
      background-color: rgba(255, 255, 255, 100);
      color: rgba(16, 16, 16, 100);
      display: flex;
      flex-direction: column;
      align-items: center;
      &_address{
        height: 54px;
        width: 100%;
        opacity: 0.9;
        background: url("../../../assets/images/activity/background.png");
        display: flex;
        img{
          width: 18px;
          height: 18px;
          margin: 18px 5px 18px 10px;
          opacity: 1;
          border-radius: 6px 6px 0 0;
        }
        &_right{
          height: 18px;
          opacity: 1;
          color: rgba(255, 145, 0, 100);
          font-size: 14px;
          text-align: left;
          margin-top: 18px;
          margin-bottom: 18px;
          font-family: PingFangSC-semiBold;
          font-weight: bolder;
        }
      }
      &_content{
        width: 335px;
        &_top{
          margin-top: 18px;
          margin-bottom: 18px;
          height: 18px;
          line-height: 18px;
          width: 335px;
          display: flex;
          img{
            width: 18px;
            height: 18px;
            margin-right: 5px;
          }
          &_right{
            color: rgba(33, 33, 33, 100);
            font-size: 14px;
            text-align: left;
            font-family: PingFangSC-semiBold;
            font-weight: bolder;
          }
        }
        &_line{
          border: 1px solid #F5F5F5;
          width: 335px;
        }
        &_description{
          width: 335px;
          text-align: justify;
          font-size: 12px;
          font-family: PingFangSC-regular;
          line-height: 20px;
          margin-top: 15px;
          margin-bottom: 30px;
        }
        &_line{
          border: 1px solid #F5F5F5;
          width: 335px;
        }
        &_user{
          margin-bottom: 15px;
          margin-top: 15px;
          width: 335px;
          height: 25px;
          display: flex;
          img{
            width: 25px;
            height: 25px;
            margin-right: 8px;
            border-radius: 50%;
          }
          &_right{
            height: 25px;
            line-height: 25px;
            color: rgba(185, 190, 204, 100);
            font-size: 12px;
            text-align: left;
            font-family: PingFangSC-regular;
          }
        }
      }
    }
    &_bottom{
      position:fixed;
      bottom:0;
      width: 100%;
      height: 64px;
      background-color: rgba(255, 255, 255, 100);
      color: rgba(16, 16, 16, 100);
      font-size: 14px;
      text-align: center;
      box-shadow: 0px -1px 1px 0px rgba(238, 238, 238, 100);
      font-family: Arial;
      display: flex;
      &_left{
        align-items: center;
        display: flex;
        &_one{
          display: flex;
          img{
            width: 17px;
            height: 17px;
            margin-left: 12px;
          }
        }
        &_two{
          display: flex;
          img{
            width: 20px;
            height: 20px;
            margin-left: 28px;
            margin-top: 22px;
            margin-bottom: 22px;
          }
        }
      }
      &_right{
        flex: 1
      }
      &_visits{
        margin-left: 5px;
       // margin-top: 22px;
        //margin-bottom: 22px;
        color: rgba(244, 190, 72, 100);
        font-size: 16px;
        text-align: left;
        font-weight: bolder;
        font-family: PingFangSC-medium;
      }
      
      &_visit{
        margin-left: 5px;
        margin-top: 22px;
        margin-bottom: 22px;
        color: rgba(244, 190, 72, 100);
        font-size: 16px;
        text-align: left;
        font-weight: bolder;
        font-family: PingFangSC-medium;
      }
      .van-button{
        margin-left: 18px;
        margin-top: 10px;
        margin-bottom: 10px;
        width: 120px;
        height: 44px;
        border-radius: 6px;
        background-color: #FFBF1E;
        text-align: center;
        color: rgba(255, 255, 255, 100);
        font-size: 14px;
        font-family: PingFangSC-semiBold;
      }
    }
  }
</style>
